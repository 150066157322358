import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { deleteJSON, loadingOverlay, patchJSON } from '../../../duxfront/plugins/dux-utils';
import { Project } from '../../../global/project';
import { Translator } from '../../../global/translator';
import { ReportPlotNew } from '../report_plot_base/ReportPlotNew.jsx';
import {
  MODAL_TYPES,
  parseDate,
  reportDateRangeSelector, reportDispatch,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportSelector,
} from '../report-utils';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';

function ReportMenuDropdownRename() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['title', 'updatePath']);
  const updateReport = reportDispatch();
  const updateError = reportErrorDispatch();

  const renameReport = React.useCallback(() => {
    BootBox.prompt({
      title: `${translator.get('general.rename')} ${translator.get('titles.report')}`,
      value: report.title,
      callback(newTitle) {
        if (newTitle) {
          patchJSON(
            report.updatePath,
            { rename_report: { title: newTitle } },
            (result) => updateReport(result.object),
            () => updateError('Failed to update report.'),
          );
        }
      },
    });
  });

  return project.userCan('edit_reports') && (
    <Dropdown.Item onClick={renameReport}>
      <Icon name="edit" className="text-primary mr-2" />
      { translator.get('general.rename') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownEditCustomFields() {
  const project = new Project();
  const translator = new Translator();
  const updateSelectedModal = reportModalTypeDispatch();
  const report = reportSelector(['config']);
  const customFields = report?.config?.customFields || [];
  const customFieldsFormType = report?.config?.customFieldsFormType || 'modal';
  const canView = project.userCan('edit_reports') && customFieldsFormType === 'modal' && customFields.length > 0;

  return canView && (
    <Dropdown.Item onClick={() => updateSelectedModal(MODAL_TYPES.EDIT_CUSTOM_FIELDS)}>
      <Icon name="th" className="text-primary mr-2" />
      { translator.get('general.properties') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownAddInteractivePlot() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const addInteractivePlot = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { add_plot: { plot_type: 'interactive' } },
      (result) => setNewPlot(result.added),
      () => updateError('Failed to add new plot.'),
    );
  });

  return project.userCan('edit_reports') && (
    <Dropdown.Item onClick={addInteractivePlot}>
      <Icon name="area-chart" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.plot')}` }
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </Dropdown.Item>
  );
}

// eslint-disable-next-line no-unused-vars
function ReportMenuDropdownAddInteractiveCustomPlot() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const addInteractiveCustomPlot = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { add_plot: { plot_type: 'interactive_custom' } },
      (result) => setNewPlot(result.added),
      () => updateError('Failed to add new plot.'),
    );
  });

  return project.userCan('partner_actions') && (
    <Dropdown.Item onClick={addInteractiveCustomPlot}>
      <Icon name="area-chart" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.plot')}` }
      { ` (${translator.get('general.custom')})`}
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </Dropdown.Item>
  );
}

// eslint-disable-next-line no-unused-vars
function ReportMenuDropdownAddInteractivePieCustomPlot() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const addInteractiveCustomPlot = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { add_plot: { plot_type: 'interactive_pie_custom' } },
      (result) => setNewPlot(result.added),
      () => updateError('Failed to add new plot.'),
    );
  });

  return project.userCan('partner_actions') && (
    <Dropdown.Item onClick={addInteractiveCustomPlot}>
      <Icon name="pie-chart" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.pie_chart')}` }
      { ` (${translator.get('general.custom')})`}
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </Dropdown.Item>
  );
}

function ReportMenuDropdownAddTablePlot() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const addTablePlot = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { add_plot: { plot_type: 'table' } },
      (result) => setNewPlot(result.added),
      () => updateError('Failed to add new plot.'),
    );
  });

  return project.userCan('edit_reports') && (
    <Dropdown.Item onClick={addTablePlot}>
      <Icon name="table" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.table')}` }
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </Dropdown.Item>
  );
}

// eslint-disable-next-line no-unused-vars
function ReportMenuDropdownAddTableCustomPlot() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const addTableCustomPlot = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { add_plot: { plot_type: 'table_custom' } },
      (result) => setNewPlot(result.added),
      () => updateError('Failed to add new plot.'),
    );
  });

  return project.userCan('partner_actions') && (
    <Dropdown.Item onClick={addTableCustomPlot}>
      <Icon name="table" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.table')}` }
      { ` (${translator.get('general.custom')})`}
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </Dropdown.Item>
  );
}

function ReportMenuDropdownAddWindRosePlot() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const addWindRosePlot = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { add_plot: { plot_type: 'wind_rose' } },
      (result) => setNewPlot(result.added),
      () => updateError('Failed to add new plot.'),
    );
  });

  return project.userCan('edit_reports') && (
    <Dropdown.Item onClick={addWindRosePlot}>
      <Icon name="compass" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.wind_rose')}` }
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </Dropdown.Item>
  );
}

function ReportMenuDropdownResetVisualization() {
  const translator = new Translator();
  const project = new Project();
  const report = reportSelector(['resetVisualizationPath', 'locationCode']);
  const show = report.locationCode && project.userCan('edit_reports');

  return show && (
    <Dropdown.Item href={report.resetVisualizationPath}>
      <Icon name="backward" className="text-primary mr-2" />
      { translator.get('general.visualization-reset') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownCsvExport() {
  const translator = new Translator();
  const report = reportSelector(['csvExportPath', 'locationCode']);
  const dateRange = reportDateRangeSelector();
  const startDate = parseDate(dateRange?.startDate);
  const endDate = parseDate(dateRange?.endDate);

  const csvExport = React.useCallback(() => {
    const url = [report.csvExportPath];
    url.push(`?startDate=${startDate.format('YYYY-MM-DD+HH:mm:ss')}`);
    url.push(`&endDate=${endDate.format('YYYY-MM-DD+HH:mm:ss')}`);
    global.window.open(url.join(''), '_blank', 'noopener,noreferrer');
  });

  return report.locationCode && (
    <Dropdown.Item onClick={csvExport}>
      <Icon name="download" className="text-primary mr-2" />
      { translator.get('general.export-location-data') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownPdfExport() {
  const updateSelectedModal = reportModalTypeDispatch();
  const translator = new Translator();

  return (
    <Dropdown.Item onClick={() => updateSelectedModal(MODAL_TYPES.REPORT_EXPORT_OPTIONS)}>
      <Icon name="file-pdf-o" className="text-primary mr-2" />
      { translator.get('general.export_report') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownApplyTemplate() {
  const project = new Project();
  const translator = new Translator();
  const updateSelectedModal = reportModalTypeDispatch();

  return project.userCan('edit_reports') && (
    <Dropdown.Item onClick={() => updateSelectedModal(MODAL_TYPES.APPLY_TEMPLATE)}>
      <Icon name="magic" className="text-primary mr-2" />
      { translator.get('general.apply_template') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownSetAsPublic() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['isPrivate', 'updatePath']);
  const updateReport = reportDispatch();
  const updateError = reportErrorDispatch();

  const setAsPublic = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { set_as_public: { is_public: true } },
      (result) => updateReport(result.object),
      () => updateError('Failed to update report.'),
    );
  });

  return project.userCan('partner_actions') && report?.isPrivate && (
    <Dropdown.Item onClick={setAsPublic}>
      <Icon name="users" className="text-primary mr-2" />
      { translator.get('general.set_as_public') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownSetAsTemplate() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['isTemplate', 'updatePath']);
  const updateError = reportErrorDispatch();

  const setAsTemplate = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      { set_as_template: { is_template: true } },
      (result) => {
        window.location.href = result.object.viewPath;
      },
      () => updateError('Failed to set report as template.'),
    );
  });

  return project.userCan('create_reports') && !report?.isTemplate && (
    <Dropdown.Item onClick={setAsTemplate}>
      <Icon name="clone" className="text-primary mr-2" />
      { translator.get('general.set_as_template') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownAdvanced() {
  const translator = new Translator();
  const project = new Project();
  const updateSelectedModal = reportModalTypeDispatch();

  return project.userCan('partner_actions') && (
    <Dropdown.Item onClick={() => updateSelectedModal(MODAL_TYPES.ADVANCED)}>
      <Icon name="lock" className="text-primary mr-2" />
      { translator.get('general.advanced') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownDataSet() {
  const translator = new Translator();
  const updateSelectedModal = reportModalTypeDispatch();
  const report = reportSelector(['config']);
  const hasDataSets = (report.config.dataSets || []).length > 0;

  return hasDataSets && (
    <Dropdown.Item onClick={() => updateSelectedModal(MODAL_TYPES.DATASET)}>
      <Icon name="database" className="text-primary mr-2" />
      { translator.get('general.datasets') }
    </Dropdown.Item>
  );
}

function ReportMenuDropdownDelete() {
  const project = new Project();
  const translator = new Translator();
  const report = reportSelector(['previousPage', 'destroyPath']);
  const self = React.createRef();

  const destroyReport = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(report.destroyPath, {}, () => {
          window.location.href = report.previousPage;
        });
      },
    });
  });

  return project.userCan('edit_reports') && (
    <Dropdown.Item onClick={destroyReport}>
      <Icon name="trash" className="text-primary mr-2" />
      { translator.get('general.delete') }
    </Dropdown.Item>
  );
}

export function ReportMenuDropdown() {
  const translator = new Translator();
  const project = new Project();
  const report = reportSelector(['locationCode', 'plots', 'viewMode']);
  const isScada = report?.viewMode === 'scada';
  const show = project.userCan('edit_reports') || report.locationCode;
  const defaultShow = report.plots.length === 0;

  return show && (
    <Dropdown as="div" className="d-inline-block cursor-pointer ml-2 d-print-none" defaultShow={defaultShow}>
      <Dropdown.Toggle as="div" id="dropdown-basic">
        <Button withIcon variant="outline-secondary" size="xs" className="text-uppercase">
          <Icon name="cogs" />
          {translator.get('general.options')}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: '300px' }}>
        <ReportMenuDropdownRename />
        { !isScada && <ReportMenuDropdownEditCustomFields /> }
        { !isScada && <ReportMenuDropdownCsvExport /> }
        { !isScada && <ReportMenuDropdownPdfExport /> }
        { !isScada && <ReportMenuDropdownAddInteractivePlot /> }
        { !isScada && <ReportMenuDropdownAddTablePlot /> }
        { !isScada && <ReportMenuDropdownAddWindRosePlot /> }
        {/* <ReportMenuDropdownAddInteractiveCustomPlot /> */}
        {/* <ReportMenuDropdownAddInteractivePieCustomPlot /> */}
        {/* <ReportMenuDropdownAddTableCustomPlot /> */}
        { !isScada && <ReportMenuDropdownDataSet /> }
        { !isScada && <ReportMenuDropdownApplyTemplate /> }
        <ReportMenuDropdownSetAsPublic />
        { !isScada && <ReportMenuDropdownSetAsTemplate /> }
        { !isScada && <ReportMenuDropdownResetVisualization /> }
        <ReportMenuDropdownAdvanced />
        <ReportMenuDropdownDelete />
      </Dropdown.Menu>
    </Dropdown>
  );
}
