import React from 'react';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';

export function LocationAlertText({ state, children, className }) {
  const textColor = () => {
    switch (state) {
      case 'warning':
        return 'text-warning';
      case 'danger':
        return 'text-danger';
      case 'unknown':
        return '';
      default:
        return 'text-primary';
    }
  };

  return <div className={`${textColor()} ${className}`}>{children}</div>;
}

export function LocationAlertIcon({ state, className, hide = [] }) {
  const style = { marginLeft: '6px' };

  if (hide.includes(state)) {
    return <div />;
  }

  switch (state) {
    case 'warning':
      return <Icon name="exclamation-triangle" className={`text-warning ${className}`} style={style} />;
    case 'danger':
      return <Icon name="exclamation-circle" className={`text-danger ${className}`} style={style} />;
    case 'unknown':
      return <Icon name="exclamation-circle" className={`ext-black ${className}`} style={style} />;
    case 'success':
      return <Icon name="check-circle" className={`text-success ${className}`} style={style} />;
    default:
      return <div />;
  }
}
