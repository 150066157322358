import React from 'react';
import { BaseEdge, getSmoothStepPath } from '@xyflow/react';
import { reportPlotDataSelector } from '../report-utils';

export const ReportScadaStandardConnection = React.memo((props) => {
  const plot = props.data.plot;
  const plotData = reportPlotDataSelector(plot.key);
  const [edgePath] = getSmoothStepPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX: props.targetX,
    targetY: props.targetY,
    targetPosition: props.targetPosition,
  });

  const [style, setStyle] = React.useState(props.style);

  React.useEffect(() => {
    if (!plotData || !plotData?.data?.animated === undefined) return;

    if (plotData.data.animated) {
      setStyle({ ...style, animation: 'dashdraw 0.2s linear infinite', strokeDasharray: 5 });
      return;
    }

    setStyle(props.style);
  }, [plotData]);

  return <BaseEdge id={props.id} path={edgePath} style={style} />;
});
