import * as React from 'react';
import {
  Col, Modal, Row, Table,
} from 'react-bootstrap';
import moment from 'moment';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Translator } from '../../global/translator';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import { LocationLink } from '../../duxfront/duxstrap/components/LocationLink.jsx';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import {
  deleteJSON,
  loadingOverlay,
  updateByKey,
  removeByKey,
  postFormData,
  patchFormData,
  parseFormData,
} from '../../duxfront/plugins/dux-utils';
import { Project } from '../../global/project';
import { Authenticity } from '../../global/authenticity';
import {
  Form, HiddenInput, Input, Select, DateRangePicker, FileInput,
} from '../../duxfront/duxstrap/components/Form.jsx';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { globalObject } from '../../global/global_object';

function LocationObservationInitialLoader({ location, locationObservations }) {
  const updateLocation = globalDispatch('selectedLocation');
  const updateLocationObservations = globalDispatch('locationObservations');

  React.useEffect(() => {
    updateLocation(location);
    updateLocationObservations(locationObservations);
  });

  return null;
}

function LocationObservationsTable() {
  const translator = new Translator();
  const project = new Project();
  const locationObservations = globalSelector('locationObservations');
  const updateSelectedLocationObservation = globalDispatch('selectedLocationObservation');
  const updateModalAction = globalDispatch('modalAction');
  const canAdd = project.userCan('submit_location_observations');
  const hasApprovers = (observation) => Array.isArray(observation.formattedApprovers)
    && observation.formattedApprovers.length > 0;

  const launchModal = React.useCallback((observation = {}, action = 'new') => {
    updateSelectedLocationObservation(observation);
    updateModalAction(action);
  });

  return (
    <Table size="sm" className="m-0" hover>
      <tbody>
        { canAdd && (
          <tr key="location-observation-add-button">
            <td className="px-3 text-center" colSpan={2}>
              <Button size="xs" variant="primary" onClick={() => launchModal()} style={{ minWidth: '150px' }} withIcon>
                <Icon name="plus" />
                { translator.get('general.add').toUpperCase() }
              </Button>
            </td>
          </tr>
        )}
        { locationObservations && locationObservations.length > 0
          ? locationObservations.sort((a, b) => b.reportedAt - a.reportedAt).map((observation) => (
            <>
              <tr>
                <td colSpan={2} className="text-center">
                  {observation.createdAt}
                </td>
              </tr>
              <tr key={observation.code} className="border-top-0">
                <td className="px-3">
                  <div className="font-weight-accent fs-12 text-secondary">
                    <Button
                      size="xs"
                      variant="link"
                      className="p-0 text-secondary text-decoration-underline"
                      style={{ textDecoration: 'underline' }}
                      onClick={() => launchModal(observation, 'edit')}
                    >
                      {observation.observationGroup.name}
                    </Button>
                  </div>
                  <div className="font-weight-accent text-primary">
                    {`[${observation.formattedSeverity.toUpperCase()}] - ${observation.reportedReason}`}
                  </div>
                  <div className="fs-12 text-gray-500">
                    <div>{`${observation.observationType} (${observation.activityType})`}</div>
                    <div>{observation.formattedReportedAt}</div>
                    <div>
                      {observation.reportedFileUrl ? (
                        <a href={observation.reportedFileUrl}>
                          {translator.get('general.file')}
                        </a>
                      ) : (
                        <span>{translator.get('general.no-file-attached')}</span>
                      )}
                    </div>
                    {observation.latitude && observation.longitude && (
                    <div>
                      <LocationLink
                        latitude={observation.latitude}
                        longitude={observation.longitude}
                        className="text-underlined"
                      >
                        {translator.get('general.view_location')}
                      </LocationLink>
                    </div>
                    )}
                  </div>
                </td>
                <td className="px-3 text-right">
                  {observation.isOpen && !observation.needsApproval && (
                  <Button
                    size="xs"
                    variant="outline-primary"
                    onClick={() => launchModal(observation, 'close')}
                    withIcon
                  >
                    <Icon name="check" />
                    {translator.get('general.close').toUpperCase()}
                  </Button>
                  )}

                  {observation.isOpen
                    && hasApprovers(observation) && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-left">
                          <div className="font-weight-accent text-primary text-uppercase">
                            {translator.get('general.approvers')}
                          </div>
                          <div className="fs-12 text-gray-500">
                            <ul className="list-unstyled">
                              {observation.approverNames && observation.approverNames.length > 0 ? (
                                observation.approverNames.map((approver) => (
                                  <li key={approver.type + approver.code}>
                                    {approver.type.toUpperCase()}
                                    :
                                    {`${` ${approver.name}`}`}
                                  </li>
                                ))
                              ) : (
                                <li>Sem aprovadores</li>
                              )}
                            </ul>
                          </div>
                        </div>

                        <div className="text-right">
                          <Button
                            size="xs"
                            variant="outline-primary"
                            onClick={() => launchModal(observation, 'close')}
                            withIcon
                          >
                            <Icon name="check" />
                            {translator.get('general.close').toUpperCase()}
                          </Button>
                        </div>
                      </div>
                  )}

                  {observation.isOpen
                    && (!hasApprovers(observation) && observation.needsApproval) && (
                      <Button
                        size="xs"
                        variant="outline-warning"
                        onClick={() => launchModal(observation, 'set_approver')}
                        withIcon
                      >
                        <Icon name="user-plus" />
                        {translator.get('general.set-approvers').toUpperCase()}
                      </Button>
                  )}

                  {!observation.isOpen && (
                  <>
                    <div className="font-weight-accent text-primary text-uppercase">{observation.formattedStatus}</div>
                    <div className="fs-12 text-gray-500">
                      <div>{observation.closedReason}</div>
                      <div>{observation.formattedClosedBy}</div>
                      <div>{observation.formattedClosedAt}</div>
                      {observation.closedFileUrl ? (
                        <a href={observation.closedFileUrl}>
                          {translator.get('general.file')}
                        </a>
                      ) : (
                        <span>{translator.get('general.no-file-attached')}</span>
                      )}
                    </div>
                  </>
                  )}
                </td>
              </tr>
            </>
          ))
          : (
            <tr>
              <td className="px-3">
                <p>{translator.get('messages.no-results-found')}</p>
              </td>
            </tr>
          )}
      </tbody>
    </Table>
  );
}

function ApproversSelector({
  approvers,
  approversType,
  onApproversChange,
}) {
  const translator = new Translator();
  const approversOptions = approvers.map((k) => ({ value: k.code, name: k.name }));
  const approversTypeOptions = approversType.map((k) => ({ value: k, name: k }));

  const [approver, setApprover] = React.useState({
    code: approvers?.[0]?.code || null,
    type: approversType?.[0] || null,
  });
  const [selectedApprovers, setSelectedApprovers] = React.useState([]);

  const getApprover = React.useCallback((code) => approvers.find((k) => k.code === code));

  React.useEffect(() => {
    setApprover({
      code: approvers?.[0]?.code || null,
      type: approversType?.[0] || null,
    });
  }, [approvers, approversType]);

  const addApprover = React.useCallback(() => {
    const newApprovers = [...selectedApprovers, approver];
    setSelectedApprovers(newApprovers);
    setApprover({
      code: approvers?.[0]?.code || null,
      type: approversType?.[0] || null,
    });
    onApproversChange(newApprovers);
  });

  const removeApprover = React.useCallback((a) => {
    const newApprovers = selectedApprovers.filter((k) => k.code !== a.code || k.type !== a.type);
    setSelectedApprovers(newApprovers);
    onApproversChange(newApprovers);
  });

  return (
    <Col sm={12}>
      <Row className="gutter-1">
        <Col sm={12} md>
          <Select
            label={translator.get('general.approvers')}
            options={approversOptions}
            defaultValue={approver.code}
            onChange={(e) => setApprover({ ...approver, code: e.value })}
          />
        </Col>

        <Col sm={12} md>
          <Select
            label={translator.get('general.type')}
            options={approversTypeOptions}
            defaultValue={approver.type}
            onChange={(e) => setApprover({ ...approver, type: e.value })}
          />
        </Col>

        <Col sm={12} md={1} className="d-flex align-items-end justify-content-end mt-2 mt-md-0">
          <Button rounded icon size="sm" variant="outline-primary" onClick={addApprover}>
            <Icon name="plus" offset={1} />
          </Button>
        </Col>

        {selectedApprovers.length > 0 && (
          <Col sm={12}>
            <Table size="sm" className="m-0">
              <thead>
                <tr>
                  <th>{translator.get('general.approvers')}</th>
                  <th>{translator.get('general.approver-types')}</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {selectedApprovers.map((k) => (
                  <tr key={k.code + k.type}>
                    <td>{getApprover(k.code)?.name || 'Unknown'}</td>
                    <td>{k.type}</td>

                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <td className="p-0">
                      <div className="d-flex align-items-center justify-content-end w-100">
                        <Button
                          rounded
                          icon
                          size="sm"
                          variant="outline-danger"
                          onClick={() => removeApprover(k)}
                        >
                          <Icon name="minus" offset={1} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        )}
      </Row>

    </Col>
  );
}

function LocationObservationModal() {
  const translator = new Translator();
  const project = new Project();
  const authenticityToken = new Authenticity().token();
  const self = React.createRef();
  const formId = 'location_observation';
  const selectedLocation = globalSelector('selectedLocation');
  const selectedLocationObservation = globalSelector('selectedLocationObservation');
  const updateSelectedLocationObservation = globalDispatch('selectedLocationObservation');
  const locationObservations = globalSelector('locationObservations');
  const modalAction = globalSelector('modalAction');
  const updateLocationObservations = globalDispatch('locationObservations');
  const isUpdate = !!selectedLocationObservation?.code;
  const [observationGroup, setObservationGroup] = React.useState(null);
  const [observationType, setObservationType] = React.useState(null);
  const [activityType, setActivityType] = React.useState(null);
  const [severity, setSeverity] = React.useState(null);
  const [selectedApprovers, setSelectedApprovers] = React.useState([]);

  const closeModal = React.useCallback(() => {
    updateSelectedLocationObservation(null);
  });

  const submitLocationObservation = React.useCallback((e) => {
    e.preventDefault();

    loadingOverlay(self.current, 'show');

    const httpCall = isUpdate ? patchFormData : postFormData;
    const submissionUrl = (
      selectedLocationObservation?.updatePath || project.locationObservationsPath
    );

    const formData = parseFormData(e.target);

    if (modalAction === 'set_approver') formData.append('approvers', JSON.stringify(selectedApprovers));

    httpCall(
      submissionUrl,
      formData,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateLocationObservations(updateByKey(locationObservations, data.object));
        closeModal();
      },
    );
  });

  const destroyLocationObservation = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(
          selectedLocationObservation.updatePath,
          {},
          (data) => {
            loadingOverlay(self.current, 'hide');
            updateLocationObservations(removeByKey(locationObservations, data.object.key));
            closeModal();
          },
        );
      },
    });
  });

  const observationGroupChange = React.useCallback((target) => {
    setObservationGroup(
      project.observationGroups.find((group) => group.code === target.value),
    );
  });

  React.useEffect(() => {
    const group = selectedLocationObservation?.observationGroup || (
      selectedLocation?.observationGroups && selectedLocation?.observationGroups[0]
    );

    if (!group) return;

    const initialObservationType = selectedLocationObservation?.observationType || group.observationTypes[0];
    const initialActivityType = selectedLocationObservation?.activityType || group.activityTypes[0];
    const initialSeverity = selectedLocationObservation?.severity || group.severities[0];

    setObservationGroup(group);
    setObservationType(initialObservationType);
    setActivityType(initialActivityType);
    setSeverity(initialSeverity);
  }, [selectedLocationObservation, selectedLocation]);

  return selectedLocationObservation && (
    <Modal centered show={!!selectedLocationObservation} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {modalAction === 'close' && translator.get('general.close')}
          {modalAction === 'set_approver' && translator.get('general.set-approvers')}
          {modalAction === 'edit' && translator.get('general.edit')}
          {modalAction === 'new' && translator.get('general.add')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form
          id={formId}
          onSubmit={submitLocationObservation}
          authenticityToken={authenticityToken}
          validate
          withBorders
        >
          <HiddenInput id="location" formId={formId} defaultValue={selectedLocation?.code} />

          {modalAction === 'close' && (
            selectedLocationObservation.userCanApprove ? (
              <>
                <Col sm={12}>
                  <Input
                    id="closed_reason"
                    formId={formId}
                    label={translator.get('general.reason')}
                    defaultValue={selectedLocationObservation.closedReason}
                    placeholder="Wild fire"
                    validation="required"
                  />
                </Col>
                <Col sm={12}>
                  <FileInput
                    id="closed_file"
                    formId={formId}
                    label={translator.get('general.select-file')}
                  />
                </Col>
              </>
            ) : (
              <Col sm={12}>
                <p>{translator.get('messages.no-permissions')}</p>
              </Col>
            )
          )}

          {modalAction !== 'close' && modalAction !== 'set_approver' && (
            <>
              {selectedLocation?.observationGroups && (
                <Col sm={12}>
                  <Select
                    id="observation_group"
                    formId={formId}
                    label={translator.get('general.observation_group')}
                    value={observationGroup?.code}
                    onChange={observationGroupChange}
                    options={selectedLocation?.observationGroups.map(
                      (group) => ({ name: group.name, value: group.code }),
                    )}
                  />
                </Col>
              )}
              <Col sm={12}>
                <Select
                  id="severity"
                  formId={formId}
                  label={translator.get('general.severity')}
                  value={severity}
                  onChange={(target) => setSeverity(target.value)}
                  options={observationGroup?.severities}
                />
              </Col>
              <Col sm={6}>
                <Select
                  id="observation_type"
                  formId={formId}
                  label={translator.get('general.type')}
                  value={observationType}
                  onChange={(target) => setObservationType(target.value)}
                  options={observationGroup?.observationTypes}
                />
              </Col>
              <Col sm={6}>
                <Select
                  id="activity_type"
                  formId={formId}
                  label={translator.get('general.activity_type')}
                  value={activityType}
                  onChange={(target) => setActivityType(target.value)}
                  options={observationGroup?.activityTypes}
                />
              </Col>
              <Col sm={6}>
                <DateRangePicker
                  id="reported_at"
                  formId={formId}
                  label={translator.get('general.datetime')}
                  language={globalObject.duxfront?.layout?.language}
                  startDate={moment(selectedLocationObservation.reportedAt)}
                  singleDatePicker
                />
              </Col>
              <Col sm={6}>
                <FileInput
                  id="reported_file"
                  formId={formId}
                  label={translator.get('general.select-file')}
                />
              </Col>
              <Col sm={12}>
                <Input
                  id="reported_reason"
                  formId={formId}
                  label={translator.get('general.reason')}
                  defaultValue={selectedLocationObservation.reportedReason}
                  placeholder="Wild fire"
                  validation="required"
                />
              </Col>
            </>
          )}

          {modalAction === 'set_approver' && selectedLocationObservation.needsApproval && (
            project.userCan('manage_location_observations') ? (
              <ApproversSelector
                approvers={observationGroup?.approvers}
                approversType={observationGroup?.approverTypes}
                onApproversChange={(newApprovers) => { setSelectedApprovers(newApprovers); }}
              />
            ) : (
              <Col sm={12}>
                <p>{translator.get('messages.no-permissions')}</p>
              </Col>
            )
          )}

          <Spacer block />

          <Col sm={6}>
            { isUpdate && modalAction !== 'set_approver' && modalAction !== 'close' ? (
              <Button size="sm" variant="danger" onClick={destroyLocationObservation} withIcon block>
                <Icon name="trash" />
                { translator.get('general.delete') }
              </Button>
            ) : (
              <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                <Icon name="times" />
                { translator.get('general.cancel') }
              </Button>
            )}
          </Col>

          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" withIcon block>
              <Icon name="check" />
              { translator.get('general.save') }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function LocationObservations(props) {
  const {
    location,
    locationObservations,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <LocationObservationInitialLoader
        location={location}
        locationObservations={locationObservations}
      />
      <LocationObservationsTable />
      <LocationObservationModal />
    </GlobalWrapper>
  );
}
