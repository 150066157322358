import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalWrapper } from '../../components/GlobalWrapper.jsx';
import { Dashbox, DashboxBody } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { ManageDataSet } from './components/DataSetItems.jsx';
import { DataSetColumns } from './components/DataSetColumns.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../global/translator';
import { BootBox } from '../../duxfront/duxstrap/vendor/bootbox';
import { deleteJSON, loadingOverlay } from '../../duxfront/plugins/dux-utils';
import { DataSetEdit } from './components/DataSetEdit.jsx';

function Page({ dataSet, dataSetItems }) {
  const translator = new Translator();
  const [items, setItems] = React.useState(dataSetItems);
  const self = React.createRef();

  const deleteDataSet = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(dataSet.destroyPath, {}, () => {
          global.window.location.href = dataSet.associationPath;
        });
      },
    });
  });

  return (
    <Container className="py-3">
      <Row>
        <Col sm={12}>
          <h4 className="font-weight-accent m-0">
            {dataSet.title}
          </h4>
        </Col>

        <Col sm={12} className="mt-1">
          <Button withIcon size="xs" variant="outline-primary" href={dataSet.associationPath}>
            <Icon name="arrow-left" />
            {translator.get('general.back').toUpperCase()}
          </Button>

          <Button
            withIcon
            size="xs"
            variant="outline-danger"
            className="ml-2"
            onClick={deleteDataSet}
          >
            <Icon name="trash" />
            {translator.get('general.remove').toUpperCase()}
          </Button>

          <DataSetEdit dataSet={dataSet} />
          <DataSetColumns dataSet={dataSet} />
        </Col>

        <Col sm={12} className="mt-3">
          <Dashbox>
            <DashboxBody className="p-2">
              <ManageDataSet items={items} setDataSetItems={setItems} dataset={dataSet} />
            </DashboxBody>
          </Dashbox>
        </Col>
      </Row>
    </Container>
  );
}

export function DataSetDetails(props) {
  const { dataSet, dataSetItems } = props;

  return (
    <GlobalWrapper {...props}>
      <Page dataSet={dataSet} dataSetItems={dataSetItems} />
    </GlobalWrapper>
  );
}
