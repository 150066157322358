import moment from 'moment';
import { globalDispatch, globalMemoSelector, globalSelector } from '../../duxfront/plugins/dux-redux';
import { getJSON } from '../../duxfront/plugins/dux-utils';

export const WIDGET_TYPES = {
  INTERACTIVE: 'standard',
  INTERACTIVE_CUSTOM: 'interactive_custom',
  INTERACTIVE_PIE_CUSTOM: 'interactive_pie_custom',
  TABLE: 'table',
  TABLE_CUSTOM: 'table_custom',
  WIND_ROSE: 'wind_rose',
  TEXT_EDITOR: 'text_editor',
  TEXT_EDITOR_CUSTOM: 'text_editor_custom',
  MAP: 'map',
  PIPER_DIAGRAM: 'piper_diagram',
  SCADA_LOCATION: 'scada_location',
  SCADA_CONNECTION: 'scada_connection',
  SCADA_TEXT: 'scada_text',
  SCADA_ACCUMULATOR: 'scada_accumulator',
  SCADA_METRIC_LEGEND: 'scada_metric_legend',
};

export const MODAL_TYPES = {
  ADVANCED: 'report-advanced-update',
  EDIT_CUSTOM_FIELDS: 'report-edit-custom-fields-update',
  APPLY_TEMPLATE: 'report-apply-template-update',
  DATASET: 'report-dataset-update',
  PLOT_ADVANCED: 'report-plot-advanced-update',
  PLOT_GENERAL: 'report-plot-general-update',
  PLOT_ANNOTATIONS: 'report-plot-annotations-update',
  INTERACTIVE_PLOT_DATA: 'report-plot-interactive-data-update',
  INTERACTIVE_PLOT_AXIS: 'report-plot-interactive-axis-update',
  TABLE_PLOT_DATA: 'report-plot-table-data-update',
  GENERIC_PLOT_DATA: 'report-plot-generic-data-update',
  PROJECT_MAP_SELECTION: 'report-plot-project-map-selection',
  SCADA_ADD_LOCATION: 'report-plot-scada-add-location',
  SCALA_ADD_TEXT: 'report-plot-scada-add-text',
  SCADA_ADD_ACCUMULATOR: 'report-plot-scada-add-accumulator',
  SCADA_ADD_METRIC_LEGEND: 'report-plot-scada-add-metric-legend',
  SCADA_EDIT_LOCATION: 'report-plot-scada-edit-location',
  SCADA_EDIT_TEXT: 'report-plot-scada-edit-text',
  SCADA_EDIT_CONNECTION: 'report-plot-scada-edit-connection',
  SCADA_EDIT_ACCUMULATOR: 'report-plot-scada-edit-accumulator',
  SCADA_EDIT_METRIC_LEGEND: 'report-plot-scada-edit-metric-legend',
  REPORT_EXPORT_OPTIONS: 'report-export-options-update',
};

export function parseDate(date) {
  if (!date) return null;

  return moment(date);
}

export function parseRangeDate(startDate, endDate) {
  const parsedRangeStartDate = !startDate || startDate === 'today'
    ? moment().startOf('day')
    : moment(startDate);

  const parsedRangeEndDate = !endDate || endDate === 'today'
    ? moment().subtract(1, 'day').endOf('day')
    : moment(endDate);

  return [parsedRangeStartDate, parsedRangeEndDate];
}

export function dumpDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return date.format(format);
}

export function loadSources(report, plotOptions, setSources, updateError) {
  getJSON(
    plotOptions.locationListPath,
    { location_code: report?.locationCode },
    (data) => {
      setSources(data.locations.map((source) => (
        {
          name: source.name,
          value: source.code,
          metrics: source.metrics.map((metric) => (
            {
              name: metric.title,
              value: metric.code,
            }
          )),
        }
      )));
    },
    () => updateError('Failed to load locations'),
  );
}

export function loadLocationTreeOptions(report, setOptions, updateError) {
  getJSON(
    report.treeOptionsPath,
    {},
    (data) => {
      setOptions(data.options);
    },
    () => {
      updateError('Failed to load locations');
    },
  );
}

export function reportDispatch() {
  return globalDispatch('report');
}

export function reportSelector(attributes = null) {
  return globalSelector('report', attributes);
}

export function reportSourcesDispatch() {
  return globalDispatch('report-sources');
}

export function reportSourcesSelector() {
  return globalSelector('report-sources');
}

export function reportErrorDispatch() {
  return globalDispatch('report-error');
}

export function reportErrorSelector() {
  return globalSelector('report-error');
}

export function reportModalTypeDispatch() {
  return globalDispatch('report-modal-type');
}

export function reportModalTypeSelector() {
  return globalSelector('report-modal-type');
}

export function reportDateRangeDispatch() {
  return globalDispatch('report-date-range');
}

export function reportDateRangeSelector() {
  return globalSelector('report-date-range');
}

export function reportPlotOptionsDispatch() {
  return globalDispatch('report-plot-options');
}

export function reportPlotOptionsSelector() {
  return globalSelector('report-plot-options');
}

export function reportPlotKeysDispatch() {
  return globalDispatch('report-plot-keys');
}

export function reportPlotKeysSelector() {
  return globalSelector('report-plot-keys');
}

export function reportPlotDispatch(plotKey) {
  return globalDispatch(`report-plot:${plotKey}`);
}

export function reportPlotSelector(plotKey, attributes = null) {
  return globalSelector(`report-plot:${plotKey}`, attributes);
}

export function reportPlotMemo(plotKey, attributes = null) {
  return globalMemoSelector(`report-plot:${plotKey}`, attributes);
}

export function reportPlotSelectedKeyDispatch() {
  return globalDispatch('report-plot-selected-key');
}

export function reportPlotSelectedKeySelector() {
  return globalSelector('report-plot-selected-key');
}

export function reportPlotDataDispatch(plotKey) {
  return globalDispatch(`report-plot-data:${plotKey}`);
}

export function reportPlotDataSelector(plotKey) {
  return globalSelector(`report-plot-data:${plotKey}`);
}

export function reportPlotInformationDispatch(plotKey) {
  return globalDispatch(`report-plot-information:${plotKey}`);
}

export function reportPlotInformationSelector(plotKey) {
  return globalSelector(`report-plot-information:${plotKey}`);
}

export function reportPlotMapsDispatch(plotKey) {
  return globalDispatch(`report-plot-maps:${plotKey}`);
}

export function reportPlotMapsSelector(plotKey) {
  return globalSelector(`report-plot-maps:${plotKey}`);
}

export function reportScadaScadaLockedDispatch() {
  return globalDispatch('report-scada-locked');
}

export function reportScadaScadaLockedSelector() {
  return globalSelector('report-scada-locked');
}

export function reportScadaNewConnectionDispatch() {
  return globalDispatch('report-scada-new-connection');
}

export function reportScadaNewConnectionSelector() {
  return globalSelector('report-scada-new-connection');
}

export function reportScadaUpdateDispatch() {
  return globalDispatch('report-scada-update');
}

export function reportScadaUpdateSelector() {
  return globalSelector('report-scada-update');
}

export function reportScadaDeleteDispatch() {
  return globalDispatch('report-scada-delete');
}

export function reportScadaDeleteSelector() {
  return globalSelector('report-scada-delete');
}

export function reportScadaClickPositionDispatch() {
  return globalDispatch('report-scada-click-position');
}

export function reportScadaClickPositionSelector() {
  return globalSelector('report-scada-click-position');
}

export function reportLocationsTreeOptionsDispatch() {
  return globalDispatch('report-locations-tree-options');
}

export function reportLocationsTreeOptionsSelector() {
  return globalSelector('report-locations-tree-options');
}

export function reportPlotDataUpdateDispatch() {
  return globalDispatch('report-plot-data-update');
}

export function reportPlotDataUpdateSelector() {
  return globalSelector('report-plot-data-update');
}
