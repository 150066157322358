import * as React from 'react';
import {
  DateRangePicker,
  Input,
  Select,
  SelectTree,
} from '../../duxfront/duxstrap/components/Form.jsx';
import { parseDate } from '../report/report-utils';

function TextField({ formId, fieldDefinition, readOnly = false }) {
  return (
    <Input
      formId={formId}
      id={fieldDefinition.id}
      label={fieldDefinition.label}
      placeholder={fieldDefinition.placeholder}
      defaultValue={fieldDefinition.value}
      validation={fieldDefinition.validation}
      as={fieldDefinition.multiline ? 'textarea' : 'input'}
      rows={Number.isInteger(fieldDefinition.multiline) ? fieldDefinition.multiline : 3}
      readOnly={readOnly}
    />
  );
}

function IntegerField({ formId, fieldDefinition, readOnly = false }) {
  return (
    <Input
      formId={formId}
      id={fieldDefinition.id}
      label={fieldDefinition.label}
      placeholder={fieldDefinition.placeholder}
      defaultValue={fieldDefinition.value}
      validation={fieldDefinition.validation}
      readOnly={readOnly}
      type="number"
      step="1"
    />
  );
}

function DecimalField({ formId, fieldDefinition, readOnly = false }) {
  return (
    <Input
      formId={formId}
      id={fieldDefinition.id}
      label={fieldDefinition.label}
      placeholder={fieldDefinition.placeholder}
      defaultValue={fieldDefinition.value}
      validation={fieldDefinition.validation}
      readOnly={readOnly}
      type="number"
      step="0.01"
    />
  );
}

function DateField({ formId, fieldDefinition, readOnly = false }) {
  return (
    <DateRangePicker
      formId={formId}
      id={fieldDefinition.id}
      label={fieldDefinition.label}
      startDate={parseDate(fieldDefinition.value)}
      validation={fieldDefinition.validation}
      readOnly={readOnly}
      variant="gray"
      singleDatePicker
      timePicker={false}
    />
  );
}

function DateTimeField({ formId, fieldDefinition, readOnly = false }) {
  return (
    <DateRangePicker
      formId={formId}
      id={fieldDefinition.id}
      label={fieldDefinition.label}
      startDate={parseDate(fieldDefinition.value)}
      validation={fieldDefinition.validation}
      readOnly={readOnly}
      variant="gray"
      singleDatePicker
    />
  );
}

function SelectField({ formId, fieldDefinition, readOnly = false }) {
  return (
    <Select
      formId={formId}
      id={fieldDefinition.id}
      label={fieldDefinition.label}
      defaultValue={fieldDefinition.value}
      options={fieldDefinition.options}
      multiple={!!fieldDefinition.multiselect}
      readOnly={readOnly}
    />
  );
}

function SelectTreeField({ formId, fieldDefinition, readOnly = false }) {
  return (
    <SelectTree
      formId={formId}
      id={fieldDefinition.id}
      label={fieldDefinition.label}
      defaultValue={fieldDefinition.value}
      options={fieldDefinition.options}
      multiple={!!fieldDefinition.multiselect}
      readOnly={readOnly}
    />
  );
}

export function CoreFields({ formId, fieldDefinition, readOnly = false }) {
  switch (fieldDefinition.type) {
    case 'text':
      return <TextField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    case 'integer':
      return <IntegerField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    case 'decimal':
      return <DecimalField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    case 'date':
      return <DateField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    case 'datetime':
      // eslint-disable-next-line max-len
      return <DateTimeField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    case 'select':
      return <SelectField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    case 'select-tree':
      return <SelectTreeField formId={formId} fieldDefinition={fieldDefinition} readOnly={readOnly} />;
    default:
      return null;
  }
}
