import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import {
  loadSources,
  MODAL_TYPES,
  reportErrorDispatch,
  reportLocationsTreeOptionsSelector,
  reportModalTypeDispatch,
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  reportPlotOptionsSelector, reportPlotSelectedKeyDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
  reportSelector,
  reportSourcesDispatch,
  reportSourcesSelector,
} from '../report-utils';
import {
  Checkbox, Input, Select, SelectTree,
} from '../../../duxfront/duxstrap/components/Form.jsx';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from '../report_plot_base/ReportPlotFormWrapper.jsx';
import { removePlot } from '../report_plot_base/ReportPlotDelete.jsx';

export function ReportScadaEditLocationForm() {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const sources = reportSourcesSelector();
  const setSources = reportSourcesDispatch();
  const [selectedSource, setSelectedSource] = React.useState(null);
  const [selectedMetrics, setSelectedMetrics] = React.useState(null);
  const [barSelectedMetric, setBarSelectedMetric] = React.useState(null);
  const [showMetricBar, setShowMetricBar] = React.useState(false);
  const updateError = reportErrorDispatch();
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const locationOptions = reportLocationsTreeOptionsSelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const report = reportSelector(['updatePath']);
  const formId = 'scada_location';
  const title = `${translator.get('general.edit')} ${translator.get('titles.location')}`;

  React.useEffect(() => {
    if (!plot) {
      setSelectedSource(null);
      setSelectedMetrics(null);
      setBarSelectedMetric(null);
      return;
    }

    if (!sources) {
      loadSources(report, plotOptions, setSources, updateError);
    }

    if (sources && !selectedSource) {
      setSelectedSource(sources.find((s) => s.value === plot.locationCode));
      setSelectedMetrics(plot.metricCodes || []);
      setShowMetricBar(!!plot.metricBar);
      setBarSelectedMetric(plot.metricBar?.metricCode);
    }
  });

  const removeLocation = React.useCallback(() => {
    removePlot(plot, plotKeys, updatePlotKeys, updateError);
    updateSelectedModalType(null);
    updateSelectedPlotKey(null);
  }, [plot, plotKeys, updatePlotKeys, updateError, updateSelectedModalType, updateSelectedPlotKey]);

  const onSourceChange = React.useCallback((value) => {
    const source = sources.find((s) => s.value === value);
    setSelectedSource(source);
    setSelectedMetrics([]);
    setBarSelectedMetric(source.metrics[0].value);
  });

  const onMetricsChange = React.useCallback((target, values) => setSelectedMetrics(values));

  const onBarMetricChange = React.useCallback((_, value) => setBarSelectedMetric(value));

  return plot && selectedSource && (
    <ReportPlotModalWrapper
      title={title}
      modalType={MODAL_TYPES.SCADA_EDIT_LOCATION}
    >
      <ReportPlotFormWrapper formId={formId} onRemove={removeLocation}>
        <Col sm={6}>
          <SelectTree
            id="location"
            formId={formId}
            label={translator.get('titles.location')}
            options={locationOptions}
            defaultValue={selectedSource.value}
            onChange={onSourceChange}
          />
        </Col>
        <Col sm={6}>
          <Select
            id="image"
            formId={formId}
            label={translator.get('general.icon')}
            options={plotOptions.plotImageOptions}
            defaultValue={plot.image.value}
          />
        </Col>
        <Col sm={12}>
          <Select
            placeholder={translator.get('general.all')}
            id="metrics"
            formId={formId}
            label={translator.get('titles.parameters')}
            defaultValue={selectedMetrics}
            onChange={onMetricsChange}
            options={selectedSource.metrics}
            multiple
          />
        </Col>

        <Col sm={12}>
          <Checkbox
            id="show_metric_bar"
            formId={formId}
            label={`${translator.get('general.show')} ${translator.get('general.metric_bar')}`}
            defaultChecked={showMetricBar}
            onChange={(e) => setShowMetricBar(e.target.checked)}
            button
            block
          />
        </Col>
        { showMetricBar && (
          <Col sm={12}>
            <div className="p-2 border rounded-small">
              <Row className="gutter-2">
                <Col sm={6}>
                  <Select
                    id="metric_bar_code"
                    formId={formId}
                    label={translator.get('titles.parameter')}
                    defaultValue={barSelectedMetric}
                    onChange={onBarMetricChange}
                    options={selectedSource.metrics}
                  />
                </Col>
                <Col sm={3}>
                  <Input
                    id="metric_bar_min"
                    formId={formId}
                    label={translator.get('general.minimum')}
                    defaultValue={plot.metricBar?.min}
                    placeholder="0"
                    type="number"
                    validation="required"
                  />
                </Col>
                <Col sm={3}>
                  <Input
                    id="metric_bar_max"
                    formId={formId}
                    label={translator.get('general.maximum')}
                    defaultValue={plot.metricBar?.max}
                    placeholder="100"
                    type="number"
                    validation="required"
                  />
                </Col>
              </Row>
            </div>
          </Col>
        )}

      </ReportPlotFormWrapper>
    </ReportPlotModalWrapper>
  );
}
