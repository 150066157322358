import * as React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import {
  Checkbox,
  FileInput, Form, Input, Select,
} from '../../duxfront/duxstrap/components/Form.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Spacer } from '../../duxfront/duxstrap/components/Spacer.jsx';
import { Translator } from '../../global/translator';
import useMaps from '../../hooks/use-maps';
import { convertKmlToGeoJson, mapWeightOptions } from '../../lib/map';
import { parseFormData } from '../../duxfront/plugins/dux-utils';

export function Maps(props) {
  const translator = new Translator();
  const {
    maps,
    selectedMap,
    modal,
    canEdit,
    closeModal,
    showModal,
    createOrUpdate,
    destroy,
  } = useMaps();

  return (
    <GlobalWrapper {...props}>
      <Dashbox className="h-100">
        <DashboxTitle title={translator.get('general.maps')}>
          { canEdit && (
          <Button rounded icon size="sm" variant="outline-primary" onClick={() => showModal(null)}>
            <Icon name="plus" offset={1} />
          </Button>
          )}
        </DashboxTitle>
        <DashboxBody table style={{ maxHeight: '300px' }}>
          { maps && maps.length > 0 && maps.map((map) => (
            <tr key={map.id} className="cursor-pointer" onClick={() => showModal(map)}>
              <td className="px-3">
                <div className="font-weight-accent">{map.title}</div>
              </td>
            </tr>
          ))}

          { maps.length === 0 && (
          <tr>
            <td className="px-3">
              <p>{translator.get('messages.no-results-found')}</p>
            </td>
          </tr>
          )}
        </DashboxBody>
      </Dashbox>
      <MapModal
        show={modal}
        map={selectedMap}
        canEdit={canEdit}
        onClose={closeModal}
        createOrUpdate={createOrUpdate}
        destroy={destroy}
      />
    </GlobalWrapper>
  );
}

function MapModal({
  map,
  show,
  onClose,
  canEdit,
  createOrUpdate,
  destroy,
}) {
  const formId = 'project_map';
  const self = React.createRef();
  const translator = new Translator();
  const [geoJson, setGeoJson] = React.useState(null);
  const [readingFile, setReadingFile] = React.useState(false);
  const [uploadEnabled, setUploadEnabled] = React.useState(false);

  React.useEffect(() => {
    if (show) {
      setUploadEnabled(false);
    }
  }, [show, map]);

  const handleFileChange = async (kmlFile) => {
    setReadingFile(true);
    const data = await convertKmlToGeoJson(kmlFile);
    const jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    const file = new File([jsonBlob], `${kmlFile.name?.replace('.kml', '')}.json`, { type: 'application/json' });
    setGeoJson(file);
    setReadingFile(false);
  };

  const submitMap = React.useCallback((e) => {
    e.preventDefault();

    const data = parseFormData(e.target);

    if (geoJson) {
      data.set('kml_file', geoJson);
    }

    createOrUpdate(data);
  });

  const removeFile = React.useCallback(() => {
    setGeoJson(null);
    setUploadEnabled(true);
  });

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>
          {map ? translator.get('general.update') : translator.get('general.add')}
          { ` ${translator.get('general.map')}` }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Form id={formId} onSubmit={submitMap} validate withBorders>
          <Col sm={12}>
            <Input
              id="title"
              formId={formId}
              label={translator.get('general.title')}
              defaultValue={map?.title}
              readOnly={!canEdit}
              validation="required"
            />
          </Col>

          <Col sm={12} md>
            <Input
              formId={formId}
              id="color"
              type="color"
              defaultValue={map?.color}
              label={translator.get('general.color')}
              validation="required"
            />
          </Col>

          <Col sm={12} md>
            <Select
              formId={formId}
              id="weight"
              defaultValue={map?.weight}
              options={mapWeightOptions}
              label={translator.get('general.width')}
              validation="required"
            />
          </Col>

          <Col sm={12}>
            {!uploadEnabled && map?.kmlFileDetails?.filename && (
              <div className="d-flex align-items-center justify-content-between py-2">
                <a href={map.kmlFileUrl} target="_blank" rel="noreferrer">
                  {map.kmlFileDetails.filename}
                </a>

                <Icon name="times" className="cursor-pointer" onClick={removeFile} />
              </div>
            )}

            { (!map?.kmlFileDetails?.filename || uploadEnabled) && (
              <FileInput
                formId={formId}
                id="kml_file"
                label="KML"
                readOnly={!canEdit}
                onSelection={(files) => handleFileChange(files[0])}
                accept=".kml"
                validation="required"
              />
            )}
          </Col>

          <Col sm={12}>
            <Checkbox
              formId={formId}
              id="show_in_project_locations"
              label={translator.get('titles.show_in_project_locations')}
              defaultChecked={map?.showInProjectLocations}
            />
          </Col>

          { canEdit && (
            <>
              <Spacer block />
              <Col sm={6}>
                {
                  map
                    ? (
                      <Button size="sm" variant="danger" onClick={() => destroy()} withIcon block>
                        <Icon name="trash" />
                        { translator.get('general.delete').toUpperCase() }
                      </Button>
                    )
                    : (
                      <Button size="sm" variant="gray-200" onClick={onClose} withIcon block>
                        <Icon name="times" />
                        { translator.get('general.cancel').toUpperCase() }
                      </Button>
                    )
                }
              </Col>
              <Col sm={6}>
                <Button size="sm" variant="primary" type="submit" disabled={readingFile} withIcon block>
                  <Icon name="check" />
                  { translator.get('general.save').toUpperCase() }
                </Button>
              </Col>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
