/* globals $ bootbox */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

import { Translator, setDefault, getUrlParameter } from './dux-utils';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

// eslint-disable-next-line max-len
export const Cognito = function (pool_id, client_id, endpoint, cookie_domain, redirect_uri, signout_uri) {
  this.locale = typeof $('html').attr('lang') === 'undefined' ? 'en' : $('html').attr('lang');
  this.translator = new Translator({
    'input-account-email': {
      en: 'Inform the email registered to your account',
      'pt-br': 'Informe o e-mail cadastrado à conta',
    },
    'no-account-for-user': {
      en: 'No account found for this email address.',
      'pt-br': 'O usuário informado não possui conta em nosso sistema.',
    },
    'too-many-attempts': {
      en: 'Too many attempts. Try again later.',
      'pt-br': 'Número de tentativas esgotado. Tente novamente mais tarde.',
    },
    'input-email-verification-code': {
      en: 'Insert the verifcation code sent to your email',
      'pt-br': 'Confirme o código de verificação enviado por e-mail',
    },
    'must-contain-six-digits': {
      en: 'Must have 6 digits',
      'pt-br': 'Deve conter 6 digitos',
    },
    'type-new-password': {
      en: 'Type new password',
      'pt-br': 'Digite a nova senha',
    },
    'must-contain-number': {
      en: 'Must contain numbers',
      'pt-br': 'Deve conter números',
    },
    'password-change-successful': {
      en: 'Password successfully changed! Please sign in.',
      'pt-br': 'Senha alterada com sucesso! Por favor faça o login.',
    },
    'password-change-failed': {
      en: 'Failed to update password.',
      'pt-br': 'Erro ao alterar a senha!',
    },
    'email-already-used': {
      en: 'Email already registered. Please, try another email address or sign in to the registered account.',
      'pt-br': 'E-mail já está cadastrado em conta existente. Por favor, utilize outro e-mail ou acesse a conta existente.',
    },
    'authentication-failed-redirected': {
      en: 'Authentication method failed. Please try again.',
      'pt-br': 'Falha ao autenticar por este método. Por favor, tente novamente.',
    },
    'authentication-failed': {
      en: 'Authentication failed. Wrong username or password.',
      'pt-br': 'Falha de autenticação. Usuário ou senha incorretos.',
    },
    'send-email-confirmation-code': {
      en: 'Email not verified. Press OK to receive new verification code in your email.',
      'pt-br': 'E-mail ainda não verificado. Clique em OK para receber o código de verificação por e-mail.',
    },
    'send-email-confirmation-code-failed': {
      en: 'Could not send email verifcation code.',
      'pt-br': 'Falha ao enviar código de verificação por e-mail.',
    },
    'send-account-confirmation-code': {
      en: 'Account not verified. Press OK to receive new verification code in your email.',
      'pt-br': 'Conta ainda não verificada. Clique em OK para receber o código de verificação por e-mail.',
    },
    'send-account-confirmation-code-failed': {
      en: 'Could not send email verifcation code.',
      'pt-br': 'Falha ao enviar código de verificação por e-mail.',
    },
    'send-again': {
      en: 'send again',
      'pt-br': 'enviar novamente',
    },
    'invalid-verification-code': {
      en: 'Wrong verification code. Please try again.',
      'pt-br': 'Código de verificação inválido. Por favor, tente novamente.',
    },
    'account-verification-successful': {
      en: 'Account successfully verified! Please sign in.',
      'pt-br': 'Conta verificada com sucesso! Por favor, faça o login.',
    },
    'email-verification-successful': {
      en: 'Email successfully verified!',
      'pt-br': 'E-mail confirmado com sucesso!',
    },
    'input-password-to-continue': {
      en: 'Please, inform password for',
      'pt-br': 'Antes de continuar, informe a senha para',
    },
    'wrong-password': {
      en: 'Wrong password. Please try again.',
      'pt-br': 'Senha incorreta. Por favor, tente novamente.',
    },
    'email-update-successful': {
      en: 'Email successfully updated!',
      'pt-br': 'E-mail atualizado com sucesso!',
    },
    'email-update-failed': {
      en: 'Failed to update email. Please try again.',
      'pt-br': 'Falha ao atualizar e-mail. Por favor, tente novamente.',
    },
    'passwords-dont-match': {
      en: 'Passwords do not match. Please try again.',
      'pt-br': 'As senhas informadas não coincidem. Por favor, tente novamente.',
    },
    'password-update-successful': {
      en: 'Password successfully updated',
      'pt-br': 'Senha atualizada com sucesso!',
    },
    'password-update-failed': {
      en: 'Failed to update password. Please try again.',
      'pt-br': 'Falha ao atualizar a senha. Por favor, tente novamente.',
    },
  });
  this.wrapper = $('body');
  this.idToken = null;

  const self = this;
  const t = this.translator;
  const cognitoRedirectURI = redirect_uri;
  const cognitoSignOutURI = `${endpoint}/logout?logout_uri=${signout_uri}&client_id=${client_id}`;
  const cognitoEndpoint = endpoint;
  const cognitoCookieData = {
    domain: cookie_domain,
    secure: false,
  };
  const cognitoPoolData = {
    UserPoolId: pool_id,
    ClientId: client_id,
  };
  if (cognitoCookieData.domain.length > 0) {
    cognitoPoolData.Storage = new AmazonCognitoIdentity.CookieStorage(cognitoCookieData);
  }
  const cognitoUserPool = new AmazonCognitoIdentity.CognitoUserPool(cognitoPoolData);
  let cognitoUser = null;
  let cognitoUserAttrs = null;
  const cognitoSessionCallbacks = {
    success: [],
    error: [],
  };

  // Try to retrieve user from session and call callbacks
  const initSession = function () {
    getCurrentUser(
      (status, attrs) => {
        const username = getUserAttr('sub');
        console.log(`Found Cognito session. User: ${username}`);
        for (const i in cognitoSessionCallbacks.success) {
          cognitoSessionCallbacks.success[i](attrs);
        }
        setInterval(() => {
          refreshSession(() => {});
        }, 10000);
      },
      () => {
        console.log('No Cognito session available.');
        if (cognitoSessionCallbacks.error.length > 0) {
          for (const i in cognitoSessionCallbacks.error) {
            cognitoSessionCallbacks.error[i]();
          }
        } else {
          window.location.href = '/';
        }
      },
    );
  };

  // Register callbacks to be called on initSession
  const registerSessionCallback = function (callback, type_arg) {
    const type = setDefault(type_arg, 'success');
    if (type === 'success') {
      cognitoSessionCallbacks.success.push(callback);
    } else if (type === 'error') {
      cognitoSessionCallbacks.error.push(callback);
    }
  };

  // Get current user from local session
  const getCurrentUser = function (success, error) {
    cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          error(false, err);
        } else {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              error(false, err);
            } else {
              self.idToken = session.idToken.jwtToken;
              cognitoUserAttrs = attributes;
              success(true, attributes);
            }
          });
        }
      });
    } else {
      error(false);
    }
  };

  // Refresh of user attributes
  const refreshUserAttrs = function (callback) {
    cognitoUser.getUserData(
      (err, userData) => {
        if (err) {
          callback(false, err);
        } else {
          cognitoUserAttrs = userData.UserAttributes;
          callback(true);
        }
      },
    );
  };

  // Update session tokens
  const refreshSession = function (callback) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        callback(false, err);
      } else if (!session.isValid()) {
        const refresh_token = session.getRefreshToken();
        cognitoUser.refreshSession(refresh_token, (err, session) => {
          if (err) {
            callback(false, err);
          } else {
            reloadUserAttrs(callback);
          }
        });
      } else {
        callback(false);
      }
    });
  };

  // Force reload of user attributes from backend
  const reloadUserAttrs = function (callback) {
    cognitoUser.getUserData(
      (err, userData) => {
        if (err) {
          callback(false, err);
        } else {
          cognitoUserAttrs = userData.UserAttributes;
          callback(true);
        }
      },
      {
        bypassCache: true,
      },
    );
  };

  // Authenticate user with user name and password
  const authenticateUser = function (username, password, success, error) {
    const authenticationData = {
      Username: username.toLowerCase(),
      Password: password,
    };
    const userData = {
      Username: username.toLowerCase(),
      Pool: cognitoUserPool,
    };
    if (cognitoCookieData.domain.length > 0) {
      userData.Storage = new AmazonCognitoIdentity.CookieStorage(cognitoCookieData);
    }
    cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData,
    );
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        getCurrentUser(success, error);
      },
      onFailure(result) {
        error(false, result);
      },
    });
  };

  // Authenticate user with redirected authentication method
  const authenticateRedirectedUser = function (success, error) {
    $.ajax({
      type: 'post',
      url: `${cognitoEndpoint}/oauth2/token`,
      contentType: 'application/x-www-form-urlencoded',
      dataType: 'json',
      data: {
        grant_type: 'authorization_code',
        client_id: cognitoPoolData.ClientId,
        code: getUrlParameter('code'),
        redirect_uri: cognitoRedirectURI,
      },
      success(data) {
        const id_token = new AmazonCognitoIdentity.CognitoIdToken({
          IdToken: data.id_token,
        });
        const access_token = new AmazonCognitoIdentity.CognitoAccessToken({
          AccessToken: data.access_token,
        });
        const refresh_token = new AmazonCognitoIdentity.CognitoRefreshToken({
          RefreshToken: data.refresh_token,
        });
        const user_session = new AmazonCognitoIdentity.CognitoUserSession({
          IdToken: id_token,
          RefreshToken: refresh_token,
          AccessToken: access_token,
          ClockDrift: 0,
        });
        const userData = {
          Username: id_token.payload['cognito:username'],
          Pool: cognitoUserPool,
        };
        if (cognitoCookieData.domain.length > 0) {
          userData.Storage = new AmazonCognitoIdentity.CookieStorage(cognitoCookieData);
        }
        cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.setSignInUserSession(user_session);
        getCurrentUser(success, error);
      },
      error() {
        error(false);
      },
    });
  };

  // Get an user attribute by name
  const getUserAttr = function (name) {
    if (cognitoUserAttrs) {
      const filtered = cognitoUserAttrs.filter((x) => {
        if (x.Name === name) {
          return true;
        }
        return false;
      })[0];
      if (filtered) {
        return filtered.Value;
      }
    }
    return null;
  };

  // Redirect user to sign out path
  const userSingOut = function () {
    cognitoUser.signOut();
    window.location.href = cognitoSignOutURI;
  };

  // Require user to input password and force authentication
  const authenticationRequest = function (success, error) {
    const username = getUserAttr('email').toLowerCase();
    bootbox.prompt({
      title: `${t.get('input-password-to-continue')}: <b>${username}</b>`,
      attributes: [{
        name: 'placeholder',
        value: '***********',
      },
      {
        name: 'data-validate',
        value: 'required',
      },
      {
        name: 'type',
        value: 'password',
      },
      ],
      callback(password) {
        if (password !== null) {
          if (!$(this).find('form').duxValid()) {
            return false;
          }
          self.wrapper.loading('show');
          authenticateUser(
            username,
            password,
            (status) => {
              self.wrapper.loading('hide');
              success(true);
            },
            (status, err) => {
              self.wrapper.loading('hide');
              if (err) {
                if (err.code === 'NotAuthorizedException') {
                  bootbox.alert(t.get('wrong-password'), () => {
                    authenticationRequest(success, error);
                  });
                } else {
                  error(false, err);
                }
              } else {
                error(false);
              }
            },
          );
        }
        return true;
      },
    });
  };

  // Veriy user email by checking code sent to it.
  const verifyUserEmail = function (callback) {
    verificationRequest(
      (ver_code) => {
        self.wrapper.loading('show');
        cognitoUser.verifyAttribute('email', ver_code, {
          onSuccess(result) {
            self.wrapper.loading('hide');
            bootbox.alert(t.get('email-verification-successful'), () => {
              callback(false);
            });
          },
          onFailure(err) {
            self.wrapper.loading('hide');
            bootbox.alert(t.get('invalid-verification-code'), () => {
              verifyUserEmail(callback);
            });
          },
        });
      },
      (callback) => {
        cognitoUser.getAttributeVerificationCode('email', {
          onSuccess: callback,
          onFailure(err) {
            callback();
            bootbox.alert(t.get('send-account-confirmation-code-failed'));
          },
        });
      },
    );
  };

  // Veriy user email by checking code sent to it.
  const verifyUserAccount = function (callback) {
    verificationRequest(
      (ver_code) => {
        self.wrapper.loading('show');
        cognitoUser.confirmRegistration(ver_code, true, (err, result) => {
          self.wrapper.loading('hide');
          if (err) {
            bootbox.alert(t.get('invalid-verification-code'), () => {
              verifyUserAccount(callback);
            });
          } else {
            bootbox.alert(t.get('account-verification-successful'), () => {
              callback(true);
            });
          }
        });
      },
      (callback) => {
        cognitoUser.resendConfirmationCode(callback);
      },
    );
  };

  // Show verification request dialog
  const verificationRequest = function (verification_callback, resend_callback) {
    refreshUserAttrs(
      () => {
        let email = getUserAttr('email');
        if (!email) {
          email = cognitoUser.username;
        }

        let title = `<p class="mb-2">${t.get('input-email-verification-code')}:</p>`;
        title += `<p class="mb-2"><i class="fa fa-envelope mr-1 text-primary"></i> ${email}</p>`;
        title += '<p class="text-right"><span class="resend-ver-code btn btn-sm btn-rounded btn-with-ico btn-light">';
        title += `<i class="fa fa-refresh icon"></i>${t.get('send-again')}</span></p>`;

        bootbox.prompt({
          title,
          attributes: [
            {
              name: 'placeholder',
              value: '0 - 0 - 0 - 0 - 0 - 0',
            },
            {
              name: 'data-validate',
              value: `required & regex:\\d - \\d - \\d - \\d - \\d - \\d:${t.get('must-contain-six-digits')}`,
            },
            {
              name: 'data-mask',
              value: '0 - 0 - 0 - 0 - 0 - 0',
            },
          ],
          callback(ver_code) {
            if (ver_code !== null) {
              if (!$(this).find('form').duxValid()) {
                return false;
              }
              verification_callback(ver_code.replace(/ - /g, ''));
            }
            return true;
          },
        });
        setTimeout(() => {
          $('.resend-ver-code').click(function (e) {
            e.preventDefault();
            const $this = $(this);
            $this.find('i').addClass('fa-spin');
            resend_callback((err, result) => {
              $this.find('i').removeClass('fa-spin');
            });
          });
        }, 150);
      },
    );
  };

  // Send account verification code to email and verify it
  const requestAccountVerification = function (callback) {
    bootbox.alert(t.get('send-account-confirmation-code'), () => {
      self.wrapper.loading('show');
      cognitoUser.resendConfirmationCode((err, result) => {
        self.wrapper.loading('hide');
        if (err) {
          bootbox.alert(t.get('send-account-confirmation-code-failed'), () => {
            callback(false, err);
          });
        } else {
          verifyUserAccount(() => {
            callback(true);
          });
        }
      });
    });
  };

  // Send email confirmation code to email and verify it
  const requestEmailVerification = function (callback) {
    bootbox.alert(t.get('send-email-confirmation-code'), () => {
      self.wrapper.loading('show');
      cognitoUser.getAttributeVerificationCode('email', {
        onSuccess() {
          verifyUserEmail(() => {
            self.wrapper.loading('hide');
            callback(true);
          });
        },
        onFailure(err) {
          self.wrapper.loading('hide');
          bootbox.alert(t.get('send-email-confirmation-code-failed'), () => {
            callback(false, err);
          });
        },
      });
    });
  };

  // Creates a session from username and password inputs
  const standardSignin = function (username, password, callback) {
    self.wrapper.loading('show');
    authenticateUser(
      username.toLowerCase(),
      password,
      (status) => {
        self.wrapper.loading('hide');
        callback(true);
      },
      (status, err) => {
        if (err && err.code === 'UserNotConfirmedException') {
          self.wrapper.loading('hide');
          requestAccountVerification(callback);
        } else {
          bootbox.alert(t.get('authentication-failed'), () => {
            callback(false);
            self.wrapper.loading('hide');
          });
        }
      },
    );
  };

  // Creates a session from redirected signin code from aws endpoint (facebook or google signins)
  const redirectedSignin = function (callback) {
    authenticateRedirectedUser(
      callback,
      () => {
        bootbox.alert(t.get('authentication-failed-redirected'), () => {
          callback(false);
          // Reload page without url parameters
          window.location.href = window.location.href.substring(0, window.location.href.indexOf('?'));
        });
      },
    );
  };

  // Creates a new user
  const register = function (name, username, password, callback) {
    const attributeList = [];
    const dataName = {
      Name: 'name',
      Value: name,
    };
    const dataEmail = {
      Name: 'email',
      Value: username.toLowerCase(),
    };
    const attributeName = new AmazonCognitoIdentity.CognitoUserAttribute(dataName);
    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
    attributeList.push(attributeName);
    attributeList.push(attributeEmail);
    self.wrapper.loading('show');
    cognitoUserPool.signUp(username.toLowerCase(), password, attributeList, null, (err, result) => {
      self.wrapper.loading('hide');
      if (err) {
        if (err.code === 'UsernameExistsException') {
          bootbox.alert(t.get('email-already-used'), () => {
            callback(false, err);
          });
        } else {
          bootbox.alert(err.msg, () => {
            callback(false, err);
          });
        }
      } else {
        cognitoUser = result.user;
        verifyUserAccount(callback);
      }
    });
  };

  // Sends a code to registered email, verify it and enter a new password
  const forgotPassword = function (callback) {
    bootbox.prompt({
      title: `${t.get('input-account-email')}:`,
      attributes: [
        {
          name: 'placeholder',
          value: 'user@email.com',
        },
        {
          name: 'data-validate',
          value: 'required & email',
        },
      ],
      callback(username) {
        if (username !== null) {
          if (!$(this).find('form').duxValid()) {
            return false;
          }
          self.wrapper.loading('show');
          const userData = {
            Username: username,
            Pool: cognitoUserPool,
          };
          if (cognitoCookieData.domain.length > 0) {
            userData.Storage = new AmazonCognitoIdentity.CookieStorage(cognitoCookieData);
          }
          cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
          cognitoUser.forgotPassword({
            onSuccess(data) {},
            onFailure(err) {
              self.wrapper.loading('hide');
              if (err.code === 'UserNotFoundException') {
                bootbox.alert(t.get('no-account-for-user'));
              } else if (err.code === 'LimitExceededException') {
                bootbox.alert(t.get('too-many-attempts'));
              } else {
                bootbox.alert(err.message);
              }
            },
            inputVerificationCode(data) {
              let title = `<p class="mb-2">${t.get('input-email-verification-code')}:</p>`;
              title += `<p class="mb-2"><i class="fa fa-envelope mr-1 text-primary"></i> ${data.CodeDeliveryDetails.Destination}</p>`;
              bootbox.prompt({
                title,
                attributes: [
                  {
                    name: 'placeholder',
                    value: '0 - 0 - 0 - 0 - 0 - 0',
                  },
                  {
                    name: 'data-validate',
                    value: `required & regex:\\d - \\d - \\d - \\d - \\d - \\d:${t.get('must-contain-six-digits')}`,
                  },
                  {
                    name: 'data-mask',
                    value: '0 - 0 - 0 - 0 - 0 - 0',
                  },
                ],
                callback(ver_code) {
                  self.wrapper.loading('hide');
                  if (ver_code !== null) {
                    if (!$(this).find('form').duxValid()) {
                      return false;
                    }
                    ver_code = ver_code.replace(/ - /g, '');
                    bootbox.prompt({
                      title: `${t.get('type-new-password')}:`,
                      attributes: [
                        {
                          name: 'type',
                          value: 'password',
                        },
                        {
                          name: 'placeholder',
                          value: '******',
                        },
                        {
                          name: 'data-validate',
                          value: `required & min:8 & regex:.*\\d.*:${t.get('must-contain-number')}`,
                        },
                      ],
                      callback(password) {
                        if (password !== null) {
                          if (!$(this).find('form').duxValid()) {
                            return false;
                          }
                          self.wrapper.loading('show');
                          cognitoUser.confirmPassword(ver_code, password, {
                            onSuccess() {
                              self.wrapper.loading('hide');
                              bootbox.alert(t.get('password-change-successful'), () => {
                                callback(true);
                              });
                            },
                            onFailure() {
                              self.wrapper.loading('hide');
                              bootbox.alert(t.get('password-change-failed'), () => {
                                callback(false);
                              });
                            },
                          });
                        }
                        return true;
                      },
                    });
                  }
                  return true;
                },
              });
            },
          });
        }
        return true;
      },
    });
  };

  // Update email after requesting authentication
  const requestEmailUpdate = function (email, callback) {
    authenticationRequest(
      () => {
        const attributeList = [new AmazonCognitoIdentity.CognitoUserAttribute({
          Name: 'email',
          Value: email,
        })];
        self.wrapper.loading('show');
        cognitoUser.updateAttributes(attributeList, (err, result) => {
          self.wrapper.loading('hide');
          if (err) {
            bootbox.alert(t.get('email-update-failed'), () => {
              callback(false, err);
            });
          } else {
            bootbox.alert(t.get('email-update-successful'), () => {
              refreshSession(() => {
                verifyUserEmail(callback);
              });
            });
          }
        });
      },
      (err) => {
        bootbox.alert(t.get('email-update-failed'), () => {
          callback(false, err);
        });
      },
    );
  };

  // Update password
  const requestPasswordUpdate = function (old_pass, new_pass, conf_pass, callback) {
    if (new_pass === conf_pass) {
      cognitoUser.changePassword(old_pass, new_pass, (err, result) => {
        if (err) {
          if (err.code === 'NotAuthorizedException') {
            bootbox.alert(t.get('wrong-password'), () => {
              callback(false, err);
            });
          } else {
            bootbox.alert(t.get('password-update-failed'), () => {
              callback(false, err);
            });
          }
        } else {
          bootbox.alert(t.get('password-update-successful'), () => {
            callback(true);
          });
        }
      });
    } else {
      bootbox.alert(t.get('passwords-dont-match'), () => {
        callback(false);
      });
    }
  };

  // Delete user
  const deleteUser = function (callback) {
    cognitoUser.deleteUser((err, result) => {
      if (err) {
        callback(false, err);
      } else {
        callback(true);
        window.location.href = '/';
      }
    });
  };

  this.init = initSession;
  this.addCallback = registerSessionCallback;
  this.signup = register;
  this.signin = standardSignin;
  this.signinRed = redirectedSignin;
  this.signout = userSingOut;
  this.resetPassword = forgotPassword;
  this.userAttr = getUserAttr;
  this.emailVerification = requestEmailVerification;
  this.updateEmail = requestEmailUpdate;
  this.updatePassword = requestPasswordUpdate;
  this.removeAccount = deleteUser;
  this.refresh = refreshSession;
};
