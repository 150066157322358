import * as React from 'react';
import { Col, Row, FormLabel } from 'react-bootstrap';
import { Checkbox, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Translator } from '../../../global/translator';
import { ReportModalWrapper } from './ReportModalWrapper.jsx';
import { ReportFormWrapper } from './ReportFormWrapper.jsx';
import {
  loadSources,
  MODAL_TYPES,
  reportDispatch,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportPlotOptionsSelector,
  reportSelector, reportSourcesDispatch, reportSourcesSelector,
} from '../report-utils';

export function ReportBulkDataEditSourceForm({ formId, sourceMapping, sources }) {
  const [selectedSource, setSelectedSource] = React.useState(
    sources.find((s) => s.value === sourceMapping.sourceCode) || sources[0],
  );

  const onSourceChange = React.useCallback((target) => {
    setSelectedSource(sources.find((s) => s.value === target.value));
  });

  return (
    <Col sm={12}>
      <div className="bordered border-primary">
        <Row className="gutter-2 p-2">
          <Col sm={6}>
            <Select
              id={`source:${sourceMapping.sourceCode}`}
              formId={formId}
              label={sourceMapping.sourceLabel || sourceMapping.sourceTitle}
              defaultValue={selectedSource?.value}
              onChange={onSourceChange}
              options={sources}
            />
          </Col>
          <Col sm={6}>
            <div className="bordered p-2">
              <Row className="gutter-2">
                {sourceMapping.metrics.map((metricMapping) => (
                  <Col sm={12} key={metricMapping.metricCode}>
                    <Select
                      id={`metric:${metricMapping.metricCode}`}
                      formId={formId}
                      label={metricMapping.metricLabel || metricMapping.metricTitle}
                      defaultValue={metricMapping.metricCode}
                      options={selectedSource?.metrics || []}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export function ReportBulkDataEditForm({ template }) {
  const report = reportSelector();
  const plotOptions = reportPlotOptionsSelector();
  const updateError = reportErrorDispatch();
  const mapping = template?.config?.dataMapping || [];
  const sources = reportSourcesSelector();
  const setSources = reportSourcesDispatch();

  React.useEffect(() => {
    if (!sources) loadSources(report, plotOptions, setSources, updateError);
  });

  return sources && mapping.map((sourceMapping) => (
    <ReportBulkDataEditSourceForm
      key={sourceMapping.sourceCode}
      sources={sources}
      sourceMapping={sourceMapping}
    />
  ));
}

export function ReportApplyTemplateForm() {
  const translator = new Translator();
  const report = reportSelector();
  const updateReport = reportDispatch();
  const updateReportModalType = reportModalTypeDispatch();
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [selectedTemplateGroup, setSelectedTemplateGroup] = React.useState(null);
  const [templateOptions, setTemplateOptions] = React.useState([]);
  const formId = 'apply_template';
  const title = translator.get('general.apply_template');
  const templateGroups = report?.templateGroups.filter((g) => g.groupType !== 'accumulator');
  const templateGroupOptions = templateGroups?.map((t) => ({ name: t.title, value: t.key })) ?? [];

  if (report?.templates && report.templates.length > 0) {
    templateGroupOptions.unshift({ name: translator.get('general.ungrouped'), value: '' });
  }

  React.useEffect(() => {
    if (templateOptions.length > 0) return;

    if (templateGroupOptions[0]?.value && templateGroups && templateGroups.length > 0) {
      setSelectedTemplateGroup(templateGroups[0]);
      setTemplateOptions(templateGroups[0].templates.map((t) => ({ name: t.title, value: t.key })));
      return;
    }

    setTemplateOptions(
      report?.templates?.map((t) => ({ name: t.title, value: t.key })),
    );
  }, [report]);

  const onTemplateGroupChange = React.useCallback((target) => {
    const groupKey = target.value;

    if (!groupKey) {
      setSelectedTemplateGroup(null);
      setTemplateOptions(report?.templates?.map((t) => ({ name: t.title, value: t.key })));
      return;
    }

    const group = templateGroups.find((g) => g.key === groupKey);
    setSelectedTemplateGroup(group);
    setTemplateOptions(group.templates.map((t) => ({ name: t.title, value: t.key })));
  });

  const onTemplateChange = React.useCallback((target) => {
    setSelectedTemplate(report.templates.find((t) => (t.key === target.value)));
  });

  const onResponse = React.useCallback((data) => {
    updateReport(data.object);
    updateReportModalType(null);
    global.window.location.reload();
  });

  React.useEffect(() => {
    if (!selectedTemplate && report?.templates) setSelectedTemplate(report.templates[0]);
  });

  return report && (
    <ReportModalWrapper
      title={title}
      modalType={MODAL_TYPES.APPLY_TEMPLATE}
    >
      <ReportFormWrapper
        formId={formId}
        afterSave={onResponse}
        updatePath={report.updatePath}
      >
        <Col sm={12}>
          <Select
            id="template_group"
            formId={formId}
            label={translator.get('titles.template_group')}
            defaultValue={selectedTemplateGroup?.key}
            onChange={onTemplateGroupChange}
            options={templateGroupOptions}
          />
        </Col>

        <Col sm={12}>
          <Select
            id="template"
            formId={formId}
            label={translator.get('general.template')}
            defaultValue={selectedTemplate?.key}
            onChange={onTemplateChange}
            options={templateOptions}
          />
        </Col>

        <Col sm={12}>
          <Checkbox
            id="clear_report"
            formId={formId}
            label={translator.get('general.clear_report_data')}
            defaultChecked={false}
            block
          />
        </Col>
        { selectedTemplate && (
          <>
            <FormLabel>{ translator.get('general.template_data_mapping') }</FormLabel>
            <ReportBulkDataEditForm template={selectedTemplate} />
          </>
        )}
      </ReportFormWrapper>
    </ReportModalWrapper>
  );
}
