import * as React from 'react';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportPlotOptionsSelector,
  reportScadaClickPositionDispatch,
  reportScadaClickPositionSelector,
  reportSelector,
} from '../report-utils';
import { parseForm, patchJSON } from '../../../duxfront/plugins/dux-utils';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { ReportPlotNew } from '../report_plot_base/ReportPlotNew.jsx';
import { ContextMenuItem } from '../../../duxfront/duxdash/components/ContextMenu.jsx';
import { ReportFormWrapper } from '../report_base/ReportFormWrapper.jsx';
import { ReportModalWrapper } from '../report_base/ReportModalWrapper.jsx';

export function ReportScadaNewMetricLegendForm() {
  const self = React.createRef();
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const updateClickPosition = reportScadaClickPositionDispatch();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const clickPosition = reportScadaClickPositionSelector();
  const formId = 'scada_metric_legend';
  const title = `${translator.get('general.add')} ${translator.get('general.metric_legend')}`;
  const [newPlot, setNewPlot] = React.useState(null);
  const updateSelectedModalType = reportModalTypeDispatch();

  const addScadaLocation = React.useCallback((e) => {
    e.preventDefault();

    const formData = parseForm(e.target)[formId];
    const payload = {
      authenticity_token: formData.authenticity_token,
      add_plot: {
        plot_type: 'scada_metric_legend',
        [formId]: {
          position: clickPosition,
        },
      },
    };

    patchJSON(
      report.updatePath,
      payload,
      (result) => {
        updateClickPosition(null);
        updateSelectedModalType(null);
        setNewPlot(result.added);
      },
      () => updateError('Failed to add new plot.'),
    );
  });

  return plotOptions && (
    <ReportModalWrapper modalType={MODAL_TYPES.SCADA_ADD_METRIC_LEGEND} title={title}>
      <ReportFormWrapper self={self} formId={formId} onSave={addScadaLocation}>
        <div className="d-flex align-items-center justify-content-center p-4 w-100">
          {translator.get('messages.empty-options')}
        </div>
      </ReportFormWrapper>

      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </ReportModalWrapper>
  );
}

export function ReportScadaNewMetricLegend() {
  const translator = new Translator();
  const updateSelectedModalType = reportModalTypeDispatch();

  return (
    <ContextMenuItem onClick={() => updateSelectedModalType(MODAL_TYPES.SCADA_ADD_METRIC_LEGEND)}>
      <Icon name="info" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('general.metric_legend')}` }
    </ContextMenuItem>
  );
}
