import React from 'react';
import {
  deleteJSON, getJSON, patchFormData, postFormData,
} from '../duxfront/plugins/dux-utils';
import { Project } from '../global/project';
import { BootBox } from '../duxfront/duxstrap/vendor/bootbox';
import { Translator } from '../global/translator';

export default function useMaps() {
  const translator = new Translator();
  const project = new Project();
  const [modal, setModal] = React.useState(false);
  const [selectedMap, setSelectedMap] = React.useState(null);
  const [maps, setMaps] = React.useState([]);
  const canEdit = project.userCan('manage_project');

  function loadMaps() {
    getJSON(
      `/projects/${project.code}/maps`,
      {},
      (data) => {
        setMaps(data.object);
      },
    );
  }

  React.useEffect(() => {
    if (!project) return;
    loadMaps();
  }, []);

  function showModal(map = null) {
    if (!canEdit) return;
    setModal(true);
    setSelectedMap(map);
  }

  function closeModal() {
    setModal(false);
    loadMaps();
  }

  function createOrUpdate(map) {
    if (selectedMap) {
      patchFormData(
        `/projects/${project.code}/maps/${selectedMap.code}`,
        map,
        () => closeModal(),
        (data) => BootBox.alert(data.responseJSON.msg || 'An unknown error occurred'),
      );
      return;
    }

    postFormData(
      `/projects/${project.code}/maps`,
      map,
      () => closeModal(),
      (data) => BootBox.alert(data.responseJSON.msg || 'An unknown error occurred'),
    );
  }

  function destroy() {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;
        deleteJSON(
          `/projects/${project.code}/maps/${selectedMap.code}`,
          {},
          () => closeModal(),
          (data) => BootBox.alert(`Error: ${data.responseJSON.msg || 'An unknown error occurred'}`),
        );
      },
    });
  }

  return {
    maps,
    modal,
    selectedMap,
    canEdit,
    showModal,
    closeModal,
    createOrUpdate,
    destroy,
  };
}
