import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { ProgressBar, Row } from 'react-bootstrap';
import { reportPlotDataSelector } from '../report-utils';
import {
  Dashbox, DashboxBody, DashboxFooter, DashboxTitle,
} from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { LocationBoxButtons, LocationBoxMetrics } from '../../location/LocationBox.jsx';
import { LocationAlertIcon } from '../../location/LocationAlert.jsx';

export const ReportScadaStandardLocation = React.memo(({ data }) => {
  const plot = data.plot;
  const plotData = reportPlotDataSelector(plot.key);
  const location = plotData?.data?.location;
  const metricBar = plotData?.data?.metric_bar;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        className="d-block mx-auto"
        src={plot.image.path}
        style={{ height: plot.image.height, width: plot.image.width }}
      />
      { plot.metricBar && (
        <ProgressBar
          className="scada-progress-bar"
          min={metricBar?.min}
          max={metricBar?.max}
          now={metricBar?.metric?.latestValue}
          animated
        />
      )}
      <Dashbox style={{ maxWidth: '400px' }}>
        <Handle type="target" id="target-top" position={Position.Top} />
        <Handle type="source" id="source-top" position={Position.Top} />
        <Handle type="target" id="target-left" position={Position.Left} />
        <Handle type="source" id="source-left" position={Position.Left} />
        <Handle type="target" id="target-right" position={Position.Right} />
        <Handle type="source" id="source-right" position={Position.Right} />
        <Handle type="target" id="target-bottom" position={Position.Bottom} />
        <Handle type="source" id="source-bottom" position={Position.Bottom} />
        <DashboxTitle>
          <div className="fs-18 font-weight-accent text-primary">
            {plot.title}
          </div>

          <LocationAlertIcon state={location?.state} style={{ marginLeft: '6px' }} />
        </DashboxTitle>
        { location && (
          <>
            <DashboxBody>
              <Row className="gutter-1">
                <LocationBoxMetrics location={location} minimal={false} realTime />
              </Row>
            </DashboxBody>
            <DashboxFooter>
              <LocationBoxButtons location={location} />
            </DashboxFooter>
          </>
        )}
      </Dashbox>
    </>
  );
});
