import React from 'react';
import { Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { Translator } from '../../../global/translator';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Form } from '../../../duxfront/duxstrap/components/Form.jsx';
import { CoreFields } from '../../../components/custom_fields/CoreFields.jsx';
import {
  deleteJSON,
  loadingOverlay, parseForm, patchJSON, postJSON,
} from '../../../duxfront/plugins/dux-utils';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';
import { Spacer } from '../../../duxfront/duxstrap/components/Spacer.jsx';

export function DataSetForm({
  dataSet,
  dataSetItem,
  show,
  onClose,
}) {
  const translator = new Translator();
  const self = React.createRef();
  const formId = 'dataset_item';

  const handleSave = React.useCallback((e) => {
    e.preventDefault();

    const form = parseForm(e.target || e.current).dataset_item;

    Object.keys(form).forEach((key) => {
      const column = dataSet.columns.find((c) => c.id === key);

      if (!column) {
        return;
      }

      if (column.type === 'date') {
        form[key] = moment(form[key]).toISOString();
      }
    });

    const httpMethod = dataSetItem?.data ? patchJSON : postJSON;
    const url = dataSetItem?.data ? dataSetItem?.updatePath : dataSet.createItemPath;

    httpMethod(
      url,
      { id: dataSetItem?.code, data_set_id: dataSet.code, data: form },
      () => {
        loadingOverlay(self.current, 'hide');
        global.window.location.reload();
      },
      () => {
        loadingOverlay(self.current, 'hide');
      },
    );
  });

  const fieldDefinitionByColumn = React.useCallback((column) => ({
    id: column.id,
    label: column.name,
    type: column.type,
    value: dataSetItem?.data ? dataSetItem.data[column.id] : '',
  }));

  const deleteItem = React.useCallback(() => {
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;

        loadingOverlay(self.current, 'show');

        deleteJSON(dataSetItem.removePath, {}, () => {
          loadingOverlay(self.current, 'hide');
          onClose();
          global.window.location.reload();
        });
      },
    });
  });

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>
          {translator.get('general.data')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self}>
        <Form id={formId} onSubmit={handleSave} ref={self}>
          { dataSet.columns.map((column) => (
            <Col key={column.id} sm={6}>
              <CoreFields formId={formId} fieldDefinition={fieldDefinitionByColumn(column)} />
            </Col>
          ))}

          <Spacer block />
          <Col sm={6}>
            { dataSetItem ? (
              <Button size="sm" variant="danger" onClick={deleteItem} withIcon block>
                <Icon name="trash" />
                { translator.get('general.delete').toUpperCase() }
              </Button>
            ) : (
              <Button size="sm" variant="gray-200" onClick={onClose} withIcon block>
                <Icon name="times" />
                { translator.get('general.cancel').toUpperCase() }
              </Button>
            )}
          </Col>

          <Col sm={6}>
            <Button size="sm" variant="primary" type="submit" withIcon block>
              <Icon name="check" />
              { translator.get('general.save').toUpperCase() }
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
