/* eslint-disable func-names */
/* globals jQuery $ */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import { isBoolean, union } from 'lodash';
import { } from '../vendor/chart-zoom';
import { } from '../vendor/chart-crosshair';

global.dux_plot_bg_fill = 'white';

const piperCoordinates = {
  cations: {
    left: { x: 0, y: 0 },
    top: { x: 50, y: 86.6 },
    right: { x: 100, y: 0 },
  },
  anions: {
    left: { x: 130, y: 0 },
    top: { x: 180, y: 86.6 },
    right: { x: 230, y: 0 },
  },
  combination: {
    left: { x: 65, y: 107.8 },
    top: { x: 115, y: 194.4 },
    right: { x: 165, y: 107.8 },
    bottom: { x: 115, y: 21.2 },
  },
};

Chart.plugins.register(ChartDataLabels);
Chart.plugins.register(annotationPlugin);
Chart.plugins.register({
  beforeDraw(chartInstance) {
    const ctx = chartInstance.chart.ctx;
    ctx.fillStyle = global.dux_plot_bg_fill;
    ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
  },
});

const addVertexLabelsPlugin = {
  id: 'vertexLabels',
  afterDraw(chart) {
    const { ctx } = chart;
    ctx.save();
    ctx.fillStyle = 'blue';
    ctx.font = '12px Arial';

    const cationsLabels = [
      { x: -2, y: 4, label: '0' },
      { x: 3, y: -3, label: '100' },
      { x: 42, y: 84, label: '100' },
      { x: 56, y: 84, label: '0' },
      { x: 104, y: 4, label: '100' },
      { x: 98, y: -3, label: '0' },
    ];

    const anionsLabels = [
      { x: piperCoordinates.anions.left.x - 2, y: 4, label: '0' },
      { x: piperCoordinates.anions.left.x + 3, y: -3, label: '100' },
      { x: piperCoordinates.anions.left.x + 42, y: 84, label: '100' },
      { x: piperCoordinates.anions.left.x + 56, y: 84, label: '0' },
      { x: piperCoordinates.anions.left.x + 104, y: 4, label: '100' },
      { x: piperCoordinates.anions.left.x + 98, y: -3, label: '0' },
    ];

    const drawLabel = ({ x, y, label }) => {
      const chartArea = chart.scales;
      const xCoord = chartArea['x-axis-0'].getPixelForValue(x);
      const yCoord = chartArea['y-axis-0'].getPixelForValue(y);
      ctx.fillText(label, xCoord, yCoord);
    };

    cationsLabels.forEach(drawLabel);
    anionsLabels.forEach(drawLabel);

    ctx.restore();
  },
};

const addYAxisLabelsPlugin = {
  id: 'yAxisLabels',
  afterDraw: (chart) => {
    const { ctx } = chart;
    const top = piperCoordinates.combination.top.y;
    const bottom = piperCoordinates.combination.bottom.y;
    const combinedHeight = top - bottom;

    const labels = [
      { x: piperCoordinates.cations.top.x, y: 0, label: 'Ca' },
      { x: piperCoordinates.cations.top.x / 2 - 10, y: piperCoordinates.cations.top.y / 2, label: 'Mg' },
      { x: piperCoordinates.cations.right.x - 12, y: piperCoordinates.cations.top.y / 2, label: 'Na + K' },
      { x: piperCoordinates.anions.top.x, y: 0, label: 'Cl' },
      { x: piperCoordinates.anions.left.x + 12, y: piperCoordinates.anions.top.y / 2 + 5, label: 'HC03' },
      { x: piperCoordinates.anions.left.x + 12, y: piperCoordinates.anions.top.y / 2, label: '+' },
      { x: piperCoordinates.anions.left.x + 12, y: piperCoordinates.anions.top.y / 2 - 5, label: 'C03' },
      { x: piperCoordinates.anions.right.x - 14, y: piperCoordinates.anions.top.y / 2, label: 'SO4' },
      { x: piperCoordinates.combination.left.x, y: combinedHeight * 0.82, label: 'SO4 + Cl' },
      { x: piperCoordinates.combination.right.x, y: combinedHeight * 0.82, label: 'Ca + Mg' },
    ];

    // Set font style
    ctx.font = 'bold 14px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const drawLabel = ({ x, y, label }) => {
      const chartArea = chart.scales;
      const xCoord = chartArea['x-axis-0'].getPixelForValue(x) ?? 0;
      const yCoord = chartArea['y-axis-0'].getPixelForValue(y) ?? 0;
      ctx.fillText(
        label,
        x === 0 ? xCoord + 10 : xCoord,
        y === 0 ? yCoord + 10 : yCoord,
      );
    };

    labels.forEach(drawLabel);
  },
};

export const DuxPlot = function (el, type) {
  const self = this;

  self.colors = {
    0: '#1f77b4', // muted blue
    1: '#ff7f0e', // safety orange
    2: '#2ca02c', // cooked asparagus green
    3: '#d62728', // brick red
    4: '#9467bd', // muted purple
    5: '#8c564b', // chestnut brown
    6: '#e377c2', // raspberry yogurt pink
    7: '#7f7f7f', // middle gray
    8: '#bcbd22', // curry yellow-green
    9: '#17becf', // blue-teal
    10: '#aec7e8', // soft blue
    11: '#ffbb78', // soft orange
    12: '#98df8a', // soft green
    13: '#ff9896', // soft red
    14: '#c5b0d5', // soft purple
    15: '#c49c94', // soft brown
    16: '#f7b6d2', // soft pink
    17: '#c7c7c7', // light gray
    18: '#dbdb8d', // soft yellow-green
    19: '#9edae5', // soft teal
    blue: 'rgb(54, 162, 235)',
    green: 'rgb(75, 192, 192)',
    yellow: 'rgb(255, 205, 86)',
    orange: 'rgb(255, 159, 64)',
    red: 'rgb(255, 99, 132)',
    purple: 'rgb(153, 102, 255)',
    white: '#ffffff',
    grey: '#c3c3c3',
    black: '#000000',
  };

  function generateTriangleBackground(coordinates) {
    const rows = 10;
    const triangles = [];
    const rowHeight = coordinates.top.y / rows;
    const rowWidth = (coordinates.right.x - coordinates.left.x) / rows;

    // eslint-disable-next-line no-plusplus
    for (let row = 0; row < rows; row++) {
      const y1 = coordinates.left.y + row * rowHeight;
      const y2 = y1 + rowHeight;

      // eslint-disable-next-line no-plusplus
      for (let col = 0; col < rows - row; col++) {
        const x1 = coordinates.left.x + col * rowWidth + (row * rowWidth) / 2;
        const x2 = x1 + rowWidth;

        triangles.push({
          data: [
            { x: x1, y: y1 },
            { x: x2, y: y1 },
            { x: (x1 + x2) / 2, y: y2 },
            { x: x1, y: y1 },
          ],
          borderColor: '#6c757d',
          borderWidth: 1,
          pointRadius: 0,
        });
      }
    }

    return triangles;
  }

  function generateDiamondBackground(coordinates) {
    const diamonds = [];
    const rows = 10;
    const width = coordinates.right.x - coordinates.left.x;
    const height = coordinates.top.y - coordinates.bottom.y;
    const rowHeight = height / rows;
    const rowWidth = width / (rows * 2);
    let numDiamonds = 1;

    // eslint-disable-next-line no-plusplus
    for (let row = 0; row < rows * 2 - 1; row++) {
      const xLeft = coordinates.top.x - width / 2 + row * rowWidth;
      const xCenter = xLeft + rowWidth;
      const xRight = xLeft + rowWidth * 2;
      const rowCenterHeight = numDiamonds * rowHeight;
      const startY = coordinates.left.y - rowCenterHeight / 2;

      // eslint-disable-next-line no-plusplus
      for (let col = 0; col < numDiamonds; col++) {
        const yCenter = startY + col * rowHeight + rowHeight / 2;

        diamonds.push({
          data: [
            { x: xLeft, y: yCenter },
            { x: xCenter, y: yCenter + rowHeight / 2 },
            { x: xRight, y: yCenter },
            { x: xCenter, y: yCenter - rowHeight / 2 },
            { x: xLeft, y: yCenter },
          ],
          borderColor: '#6c757d',
          borderWidth: 1,
          pointRadius: 0,
        });
      }

      // Increase until the middle row, then decrease
      numDiamonds = row < rows - 1 ? numDiamonds + 1 : numDiamonds - 1;
    }

    return diamonds;
  }

  const options = function () {
    if (self.type === 'doughnut' || self.type === 'pie') {
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 25,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        animation: {
          duration: 0,
          animateRotate: true,
          animateScale: false,
        },
        plugins: {
          crosshair: false,
          zoom: {},
          datalabels: {
            formatter: (val, ctx) => {
              if (!val) return null;
              return ctx.chart.data.labels[ctx.dataIndex];
            },
            anchor: 'end',
            align: 'end',
            backgroundColor: (ctx) => { // eslint-disable-line arrow-body-style
              return ctx.chart.data.datasets[ctx.datasetIndex].backgroundColor[ctx.dataIndex];
            },
            borderRadius: 3,
            color: 'white',
          },
        },
      };
    }

    if (self.type === 'piper') {
      return {
        layout: {
          padding: {
            top: 20, // Add padding at the top
            bottom: 20, // Adjust as needed
            left: 20, // Optional, adjust as needed
            right: 20, // Optional, adjust as needed
          },
        },
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        elements: {
          line: {
            tension: false,
          },
        },
        legend: {
          display: false,
        },
        hoverMode: 'index',
        scales: {
          xAxes: [
            {
              type: 'linear', min: 0, max: 235, ticks: { display: false },
            },
          ],
          yAxes: [{
            type: 'linear', min: 0, max: 200, ticks: { display: false },
          }],
        },
        plugins: {
          crosshair: {},
          zoom: {},
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          enabled: true,
          filter: (item) => item.datasetIndex < (self.chart.data.datasets.length - 213),
          callbacks: {
            title: () => null,
            label: (item) => {
              const dataset = self.chart.data.datasets[item.datasetIndex];
              const data = dataset.data[item.index];
              return data.label;
            },
          },
        },
      };
    }

    return {
      responsive: true,
      responsiveAnimationDuration: 0,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      elements: {
        line: {
          tension: false,
        },
      },
      tooltips: {
        mode: 'nearest',
        position: 'nearest',
        intersect: false,
        caretPadding: 0,
        caretSize: 5,
      },
      legend: {
        display: false,
        position: 'bottom',
      },
      hoverMode: 'index',
      scales: {
        xAxes: [],
        yAxes: [],
      },
      plugins: {
        crosshair: {},
        zoom: {},
        datalabels: {
          display: false,
        },
      },
    };
  };

  const xaxis = function (type) {
    type = typeof type === 'undefined' ? 'time' : type;
    if (type === 'time') {
      return {
        id: 'x-axis-0',
        type: 'time',
        stacked: false,
        offset: true,
        ticks: {
          maxRotation: 0,
          autoSkipPadding: 10,
          major: {
            enabled: true,
          },
        },
        time: {
          unit: false,
          parser: 'YYYY-MM-DD HH:mm:ss',
          tooltipFormat: 'll HH:mm:ss',
          displayFormats: {
            millisecond: 'ss',
            second: 'mm:ss',
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'MMM D',
            week: 'll',
            month: 'MMM YYYY',
            quarter: 'MMM YYYY',
          },
        },
        scaleLabel: {
          display: false,
          labelString: false,
        },
        gridLines: {
          drawOnChartArea: true,
          offsetGridLines: false,
        },
      };
    } if (type === 'category') {
      return {
        id: 'x-axis-0',
        type: 'category',
        stacked: false,
        offset: true,
        scaleLabel: {
          display: false,
          labelString: false,
        },
        gridLines: {
          drawOnChartArea: true,
          offsetGridLines: false,
        },
      };
    }
    return {
      id: 'x-axis-0',
      type: 'linear',
      stacked: false,
      offset: true,
      ticks: {
        type: 'linear',
        min: 0,
        max: 0,
        stepSize: 1,
        maxRotation: 0,
        autoSkipPadding: 10,
      },
      scaleLabel: {
        display: false,
        labelString: false,
      },
      gridLines: {
        drawOnChartArea: true,
        offsetGridLines: false,
      },
    };
  };

  const yaxis = function (title, id, position) {
    return {
      id,
      type: 'linear',
      display: true,
      stacked: false,
      position,
      scaleLabel: {
        display: title !== null,
        labelString: title,
      },
      ticks: {
        suggestedMin: null,
        suggestedMax: null,
      },
      gridLines: {
        drawOnChartArea: true,
      },
    };
  };

  const dataset = function (title, color, type, axis) {
    const isStacked = self.chart.options.scales.yAxes.filter((a) => a.id === axis)[0].stacked;
    let bgcolor = color;
    let fill = false;

    if (type === 'area') {
      type = 'line';
      fill = true;
    }
    if (bgcolor.includes('#') && bgcolor.length < 8 && !isStacked) {
      bgcolor += 'B0';
    } else if (bgcolor.includes('rgb(')) {
      bgcolor = bgcolor.replace('rgb(', 'rgba(');
      bgcolor = bgcolor.replace(')', ', 0.69)');
    }
    return {
      yAxisID: axis,
      label: title,
      type,
      fill,
      steppedLine: false,
      backgroundColor: bgcolor,
      borderColor: color,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
      data: [],
    };
  };

  const parseData = function (data) {
    const rows = [];
    if (typeof data.x !== 'undefined') {
      for (let i = 0; i < data.x.length; i += 1) {
        rows.push({
          x: data.x[i],
          y: data.y[i],
        });
      }
    }
    return rows;
  };

  const zoom = function () {
    return {
      pan: {
        enabled: true,
        mode: 'x',
        speed: 10,
      },
      zoom: {
        enabled: true,
        drag: false,
        mode: 'x',
        speed: 0.1,
      },
    };
  };

  const crosshair = function () {
    return {
      line: {
        enabled: true,
        color: '#3c3c3c',
        width: 1,
        dashPattern: [6, 3],
      },
    };
  };

  this.element = typeof el === 'undefined' ? null : $(el);

  this.type = typeof type === 'undefined' ? 'time' : type;

  this.setup = function (el) {
    if (el) self.element = $(el);

    if (self.type === 'doughnut' || self.type === 'pie') {
      self.chart = new Chart(self.element, {
        type: self.type,
        data: {
          datasets: [],
          labels: [],
        },
        options: options(),
      });
    } else if (self.type === 'piper') {
      const coordinates = {
        cations: {
          left: { x: 0, y: 0 },
          top: { x: 50, y: 86.6 },
          right: { x: 100, y: 0 },
        },
        anions: {
          left: { x: 130, y: 0 },
          top: { x: 180, y: 86.6 },
          right: { x: 230, y: 0 },
        },
        combination: {
          left: { x: 65, y: 107.8 },
          top: { x: 115, y: 194.4 },
          right: { x: 165, y: 107.8 },
          bottom: { x: 115, y: 21.2 },
        },
      };

      self.chart = new Chart(self.element, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Cations',
              borderColor: 'black',
              borderWidth: 2,
              pointRadius: 0,
              data: [
                coordinates.cations.left,
                coordinates.cations.top,
                coordinates.cations.right,
                coordinates.cations.left,
              ],
            },
            {
              label: 'Anions',
              borderColor: 'black',
              borderWidth: 2,
              pointRadius: 0,
              data: [
                coordinates.anions.left,
                coordinates.anions.top,
                coordinates.anions.right,
                coordinates.anions.left,
              ],
            },
            {
              label: 'Combination',
              borderColor: 'black',
              borderWidth: 2,
              pointRadius: 0,
              data: [
                coordinates.combination.left,
                coordinates.combination.top,
                coordinates.combination.right,
                coordinates.combination.bottom,
                coordinates.combination.left,
              ],
            },
            ...generateTriangleBackground(coordinates.cations),
            ...generateTriangleBackground(coordinates.anions),
            ...generateDiamondBackground(coordinates.combination),
          ],
          labels: [],
        },
        plugins: [addVertexLabelsPlugin, addYAxisLabelsPlugin],
        options: options(),
      });
    } else {
      self.chart = new Chart(self.element, {
        type: 'line',
        data: {
          datasets: [],
        },
        options: options(),
      });
      self.chart.options.scales.xAxes = [];
      self.chart.options.scales.yAxes = [];
      if (self.type === 'time') {
        self.addX('time');
      } else if (self.type === 'line') {
        self.addX('line');
      } else {
        self.addX('category');
      }
    }
  };

  this.destroy = function () {
    self.chart.destroy();
  };

  this.reset = function () {
    self.destroy();
    self.setup();
  };

  this.setType = function (type) {
    self.type = type || 'time';
  };

  this.addX = function (type, options) {
    type = typeof type === 'undefined' ? 'time' : type;
    options = typeof options === 'undefined' ? {} : options;
    self.chart.options.scales.xAxes.push(xaxis(type));
    jQuery.extend(true, self.chart.options.scales.xAxes[0], options);
  };

  this.setXRange = function (min, max) {
    if (typeof min === 'undefined' || typeof max === 'undefined') {
      max = -Infinity;
      min = Infinity;

      for (let i = 0; i < self.chart.data.datasets.length; i += 1) {
        self.chart.data.datasets[i].data.filter((d) => {
          const value = self.type === 'time' ? moment(d.x) : d.x;
          if (value > max) max = value;
          if (value < min) min = value;
        });
      }
    }

    if (min === Infinity) min = 0;
    if (max === -Infinity) max = 1;

    if (self.type === 'time') {
      self.chart.options.scales.xAxes[0].ticks.min = min;
      self.chart.options.scales.xAxes[0].ticks.max = max;
    } else {
      delete self.chart.options.scales.xAxes[0].ticks.min;
      delete self.chart.options.scales.xAxes[0].ticks.max;
      self.chart.options.scales.xAxes[0].ticks.suggestedMin = min;
      self.chart.options.scales.xAxes[0].ticks.suggestedMax = max;
    }
  };

  this.setX = function (options) {
    type = typeof type === 'undefined' ? {} : options;
    if (options?.time?.unit) self.chart.options.tooltips.mode = 'index';
    jQuery.extend(true, self.chart.options.scales.xAxes[0], options);
  };

  this.addY = function (title, position, options, id) {
    position = typeof position === 'undefined' ? 'left' : position;
    options = typeof options === 'undefined' ? {} : options;
    id = typeof id === 'undefined' ? 'axis-1' : id;
    self.chart.options.scales.yAxes.push(jQuery.extend(true, yaxis(title, id, position), options));
  };

  this.setYRange = function (min, max, gap, id) {
    let index = 0;

    id = typeof id === 'undefined' ? 'axis-1' : id;
    gap = typeof gap === 'undefined' ? 0.1 : gap;

    for (let i = 0; i < self.chart.options.scales.yAxes.length; i += 1) {
      if (self.chart.options.scales.yAxes[i].id === id) {
        index = i;
      }
    }

    if (typeof min === 'undefined' || typeof max === 'undefined') {
      let axis_max = -Infinity;
      let axis_min = Infinity;
      for (let i = 0; i < self.chart.data.datasets.length; i += 1) {
        if (self.chart.data.datasets[i].yAxisID === id) {
          self.chart.data.datasets[i].data.filter((d) => {
            if (!d.y) return;
            if (d.y > axis_max) {
              axis_max = d.y;
            }
            if (d.y < axis_min) {
              axis_min = d.y;
            }
          });
          if (self.chart.data.datasets[i].type === 'bar') {
            if (axis_min > 0) {
              axis_min = 0;
            }
          }
        }
      }
      const bound_delta = (axis_max - axis_min) * gap;
      axis_max += bound_delta;
      if ((axis_min - bound_delta < 0 && axis_min >= 0)) {
        axis_min = 0;
      } else {
        axis_min -= bound_delta;
      }
      if (axis_max === axis_min) {
        axis_max = axis_min + 1;
      }
      if (typeof min === 'undefined') {
        min = axis_min;
      }
      if (typeof max === 'undefined') {
        max = axis_max;
      }
    }

    if (min === Infinity) {
      min = 0;
    }
    if (max === -Infinity) {
      max = 1;
    }

    delete self.chart.options.scales.yAxes[index].ticks.min;
    delete self.chart.options.scales.yAxes[index].ticks.max;
    self.chart.options.scales.yAxes[index].ticks.suggestedMin = min;
    self.chart.options.scales.yAxes[index].ticks.suggestedMax = max;
  };

  this.setY = function (options, id) {
    id = typeof id === 'undefined' ? 'axis-1' : id;
    let index = 0;
    for (let i = 0; i < self.chart.options.scales.yAxes.length; i += 1) {
      if (self.chart.options.scales.yAxes[i].id === id) {
        index = i;
      }
    }
    jQuery.extend(true, self.chart.options.scales.yAxes[index], options);
  };

  this.addDataset = function (title, color, type, options, axis) {
    type = typeof type === 'undefined' ? 'line' : type;
    options = typeof options === 'undefined' ? {} : options;
    axis = typeof axis === 'undefined' ? 'axis-1' : axis;
    self.chart.data.datasets.push(jQuery.extend(true, dataset(title, color, type, axis), options));
    return self.chart.data.datasets.length - 1;
  };

  this.addPieDataset = function (title, options) {
    self.chart.data.datasets.push(jQuery.extend(
      true,
      {
        label: title,
        data: [],
        backgroundColor: [],
      },
      options,
    ));
  };

  this.addPiperDataset = function (title, color, pointStyle, options = {}) {
    const dataset = jQuery.extend(
      true,
      {
        label: title,
        data: [],
        borderColor: color,
        backgroundColor: color,
        showLine: false,
        pointStyle,
        pointRadius: 5,
        pointHoverRadius: 7,
      },
      options,
    );

    self.chart.data.datasets = [dataset, ...self.chart.data.datasets];

    return self.chart.data.datasets.length - 1;
  };

  this.setDataset = function (options, index) {
    index = typeof index === 'undefined' ? 0 : index;
    jQuery.extend(true, self.chart.data.datasets[index], options);
  };

  this.setData = function (data, index) {
    if (!data) {
      data = { x: [], y: [] };
    }

    // data: {x: [], y: [], color: []}
    index = typeof index === 'undefined' ? 0 : index;
    if (self.type === 'doughnut' || self.type === 'pie' || self.type === 'category') {
      self.chart.data.labels = union(self.chart.data.labels, data.x);
      self.chart.data.datasets[index].data = [];
      self.chart.data.labels.forEach((label) => {
        const dataIndex = data.x.indexOf(label);
        self.chart.data.datasets[index].data.push(dataIndex === -1 ? 0 : data.y[dataIndex]);
      });

      if (self.type !== 'category') {
        self.chart.data.datasets[index].backgroundColor = [];
        if (typeof data.color === 'undefined') {
          for (let i = 0; i < self.chart.data.labels.length; i += 1) {
            self.chart.data.datasets[index].backgroundColor.push(self.colors[i]);
          }
        } else {
          self.chart.data.datasets[index].backgroundColor = data.color;
        }
      }
    } else if (self.type === 'piper') {
      self.chart.data.datasets[index].data = data;
    } else {
      self.chart.data.datasets[index].data = parseData(data);
    }
  };

  this.setOptions = function (options) {
    jQuery.extend(true, self.chart.options, options);
  };

  this.setLineTension = function (tension) {
    self.chart.options.elements.line.tension = isBoolean(tension) ? 0.4 : tension;
  };

  this.enableZoom = function (options) {
    options = typeof options === 'undefined' ? {} : options;
    self.chart.options.plugins.zoom = jQuery.extend(true, zoom(), options);
  };

  this.enableCrosshair = function (options) {
    options = typeof options === 'undefined' ? {} : options;
    self.chart.options.tooltips.position = 'average';
    self.chart.options.plugins.crosshair = jQuery.extend(true, crosshair(), options);
  };

  this.enableLegend = function (position) {
    self.chart.options.legend.display = true;
    if (typeof position !== 'undefined') {
      self.chart.options.legend.position = position;
    }
  };

  this.enableDataLabels = function (dataLabel) {
    const baseOptions = {
      display: true,
      align: 'top',
      font: {
        weight: 'bold',
      },
      formatter(val, ctx) {
        if (!val) return null;
        return ctx.chart.data.datasets[ctx.datasetIndex].data[ctx.dataIndex].y;
      },
    };

    // more info: https://chartjs-plugin-datalabels.netlify.app/guide/options.html#scriptable-options
    self.chart.options.plugins.datalabels = {
      ...baseOptions,
      ...dataLabel,
    };
  };

  this.apply = function () {
    self.chart.update();
  };

  this.download = function (name, type) {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = self.element.get(0).toDataURL(`image/${type}`, 1);
    hiddenElement.target = '_blank';
    hiddenElement.download = name;
    hiddenElement.click();
  };

  this.clipboard = function () {
    self.element.get(0).toBlob((blob) => {
      const item = new ClipboardItem({ 'image/png': blob });
      navigator.clipboard.write([item]);
    });
  };

  this.zoomOut = () => {
    self.chart.options.scales.yAxes.forEach((axis) => {
      self.setYRange(undefined, undefined, undefined, axis.id);
    });
    self.setXRange(undefined, undefined);
    self.apply();
  };

  this.applyAnnotations = (annotations) => {
    self.chart.options.annotation = {
      annotations: annotations.map((a) => {
        let axis = null;

        if (a.orientation === 'vertical') {
          axis = self.chart.options.scales.xAxes[0].id;
        }

        if (a.orientation === 'horizontal') {
          const index = self.chart.options.scales.yAxes.findIndex((y) => (
            y.scaleLabel.labelString === a.axis
          ));

          axis = index >= 0
            ? self.chart.options.scales.yAxes[index].id
            : null;
        }

        return {
          type: 'line',
          value: a.value,
          borderColor: a.color,
          mode: a.orientation,
          scaleID: axis,
          label: {
            content: a.label,
            position: a.labelPosition,
            enabled: axis !== null,
          },
        };
      }),
    };
  };

  if (this.element) this.setup();
};
