import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportPlotDataUpdateDispatch,
  reportPlotDataUpdateSelector,
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  reportPlotOptionsSelector, reportPlotSelectedKeyDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';
import { Checkbox, Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from '../report_plot_base/ReportPlotFormWrapper.jsx';
import { removePlot } from '../report_plot_base/ReportPlotDelete.jsx';

export function ReportScadaEditConnectionForm() {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const updateError = reportErrorDispatch();
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const formId = 'scada_connection';
  const title = `${translator.get('general.edit')} ${translator.get('general.connection')}`;
  const plotDataUpdates = reportPlotDataUpdateSelector() ?? [];
  const setPlotDataUpdates = reportPlotDataUpdateDispatch();

  const removeConnection = React.useCallback(() => {
    removePlot(plot, plotKeys, updatePlotKeys, updateError, () => {
      const connection = plot.config.plots[0];
      setPlotDataUpdates([...plotDataUpdates, connection.source, connection.target]);
      updateSelectedModalType(null);
      updateSelectedPlotKey(null);
    });
  }, [plot, plotKeys, updatePlotKeys, updateError, updateSelectedModalType, updateSelectedPlotKey]);

  const afterSave = React.useCallback(() => {
    const connection = plot.config.plots[0];
    setPlotDataUpdates([...plotDataUpdates, reportPlotSelectedKey, connection.source, connection.target]);
    updateSelectedModalType(null);
    updateSelectedPlotKey(null);
  });

  return plotOptions && plot && plot.config.plots[0] && (
    <ReportPlotModalWrapper title={title} modalType={MODAL_TYPES.SCADA_EDIT_CONNECTION}>
      <ReportPlotFormWrapper formId={formId} onRemove={removeConnection} afterSave={afterSave}>
        <Col sm={6}>
          <Select
            id="width"
            formId={formId}
            label={translator.get('general.width')}
            defaultValue={plot.config.plots[0].width}
            options={['1px', '2px', '3px', '4px', '5px']}
          />
        </Col>
        <Col sm={6}>
          <Input
            id="color"
            formId={formId}
            label={translator.get('general.color')}
            defaultValue={plot.config.plots[0].color}
            placeholder="#3c3c3c"
            validation="required"
            type="color"
          />
        </Col>

        <Col sm={12}>
          <Select
            id="metric"
            formId={formId}
            label={translator.get('titles.parameter')}
            defaultValue={plot.config.plots[0].metric}
            options={[
              { name: translator.get('general.none'), value: '' },
              ...plot.config.plots[0].metrics ?? [],
            ]}
          />
        </Col>

        <Col sm={12}>
          <Checkbox
            id="animated"
            formId={formId}
            label={translator.get('general.animated_metric')}
            defaultChecked={plot.config.plots[0].animated}
            button
            block
          />
        </Col>
      </ReportPlotFormWrapper>
    </ReportPlotModalWrapper>
  );
}
