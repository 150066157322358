import * as React from 'react';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportErrorDispatch,
  reportModalTypeDispatch,
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  reportPlotSelectedKeyDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';
import { removePlot } from '../report_plot_base/ReportPlotDelete.jsx';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from '../report_plot_base/ReportPlotFormWrapper.jsx';

export function ReportScadaEditMetricLegendForm() {
  const translator = new Translator();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const updateError = reportErrorDispatch();
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const formId = 'scada_metric_legend';
  const title = `${translator.get('general.edit')} ${translator.get('general.metric_legend')}`;

  const remove = React.useCallback(() => {
    removePlot(plot, plotKeys, updatePlotKeys, updateError);
    updateSelectedModalType(null);
    updateSelectedPlotKey(null);
  }, [plot, plotKeys, updatePlotKeys, updateError, updateSelectedModalType, updateSelectedPlotKey]);

  return plot && (
    <ReportPlotModalWrapper title={title} modalType={MODAL_TYPES.SCADA_EDIT_METRIC_LEGEND}>
      <ReportPlotFormWrapper formId={formId} onRemove={remove}>
        <div className="d-flex align-items-center justify-content-center p-4 w-100">
          {translator.get('messages.empty-options')}
        </div>
      </ReportPlotFormWrapper>
    </ReportPlotModalWrapper>
  );
}
