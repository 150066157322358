import React from 'react';
import { reportPlotSelector, WIDGET_TYPES } from '../report-utils';

export const ReportScadaPlotStateManager = React.memo(({ plotKey, setNodes, setEdges }) => {
  const plot = reportPlotSelector(plotKey);

  const buildNode = React.useCallback(() => {
    if (plot.widget === WIDGET_TYPES.SCADA_LOCATION) {
      return {
        id: plotKey,
        type: 'standardLocation',
        data: { plot },
        position: plot.position,
      };
    }

    if (plot.widget === WIDGET_TYPES.SCADA_TEXT) {
      return {
        id: plotKey,
        type: 'text',
        data: { plot },
        position: plot.position,
      };
    }

    if (plot.widget === WIDGET_TYPES.SCADA_ACCUMULATOR) {
      return {
        id: plotKey,
        type: 'accumulator',
        data: { plot },
        position: plot.position,
      };
    }

    if (plot.widget === WIDGET_TYPES.SCADA_METRIC_LEGEND) {
      return {
        id: plotKey,
        type: 'metricLegend',
        data: { plot },
        position: plot.position,
      };
    }

    return null;
  }, [plot]);

  const buildEdge = React.useCallback(() => {
    if (plot.widget !== WIDGET_TYPES.SCADA_CONNECTION) return null;
    if (!plot.config.plots) return;
    if (plot.config.plots.length === 0) return;

    const connection = plot.config.plots[0];

    return {
      id: plotKey,
      type: 'standardConnection',
      source: connection.source,
      target: connection.target,
      sourceHandle: connection.sourceHandle,
      targetHandle: connection.targetHandle,
      animated: false,
      style: { stroke: connection.color, strokeWidth: connection.width },
      data: { plot },
    };
  }, [plot]);

  React.useEffect(() => {
    if (!plot) return;

    const node = buildNode();
    if (node) setNodes((nds) => nds.concat(node));

    const edge = buildEdge();
    if (edge) setEdges((eds) => eds.concat(edge));
  });

  return null;
});
