/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { Col, Dropdown, Table } from 'react-bootstrap';
import { Dashbox, DashboxBody } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Project } from '../../../global/project';
import { randomHex } from '../../../duxfront/plugins/dux-utils';
import {
  ReportPlotBoxFooter,
  ReportPlotBoxFullScreenIcon,
  ReportPlotBoxHeader,
} from '../report_plot_base/ReportPlotBox.jsx';
import { ReportPlotDataLoader } from '../report_plot_base/ReportPlotDataLoader.jsx';
import { ReportPlotRefreshData } from '../report_plot_base/ReportPlotRefreshData.jsx';
import { ReportPlotGeneral } from '../report_plot_base/ReportPlotGeneral.jsx';
import { ReportPlotClone } from '../report_plot_base/ReportPlotClone.jsx';
import { ReportPlotDelete } from '../report_plot_base/ReportPlotDelete.jsx';
import { ReportPlotAdvanced } from '../report_plot_base/ReportPlotAdvanced.jsx';
import { ReportTablePlotCopy, ReportTablePlotCsv } from './ReportTablePlotBase.jsx';
import { reportPlotSelector } from '../report-utils';
import { CustomField } from '../../custom_fields/CustomField.jsx';
import { plotConfigSelector } from '../report_interactive_plot/plot-utils';

function ReportTableCustomPlotBox({ plotKey, boxContentRef }) {
  const { plot, plotConfig } = plotConfigSelector(plotKey);
  const [fullScreen, setFullScreen] = React.useState(false);

  return plotConfig && !plotConfig.hidden && (
    <Col sm={plot.columnSize} className="print-no-page-break" style={{ order: plot.order }}>
      <Dashbox id={plotKey} fullViewPort={fullScreen}>
        <ReportTablePlotCustomHeader
          plotKey={plot.key}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          boxContentRef={boxContentRef}
        />
        <ReportTableCustomPlotBody plotKey={plot.key} boxContentRef={boxContentRef} />
        <ReportPlotBoxFooter plotKey={plot.key} />
      </Dashbox>
    </Col>
  );
}

function ReportTablePlotCustomHeader({
  plotKey, fullScreen, setFullScreen, boxContentRef,
}) {
  const plot = reportPlotSelector(plotKey, ['key', 'title']);
  const project = new Project();

  return (
    <ReportPlotBoxHeader plotKey={plot.key}>
      <ReportPlotBoxFullScreenIcon fullScreen={fullScreen} setFullScreen={setFullScreen} />
      { project.userCan('edit_reports') && (
        <Dropdown as="div" className="d-inline-block cursor-pointer ml-2">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Icon name="ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ReportPlotRefreshData plotKey={plot.key} />
            <ReportPlotGeneral plotKey={plot.key} />
            <ReportTablePlotCopy boxContentRef={boxContentRef} />
            <ReportTablePlotCsv plotKey={plot.key} boxContentRef={boxContentRef} />
            <ReportPlotClone plotKey={plot.key} />
            <ReportPlotDelete plotKey={plot.key} />
            <ReportPlotAdvanced plotKey={plot.key} />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ReportPlotBoxHeader>
  );
}

function ReportTableCustomPlotBody({ plotKey, boxContentRef }) {
  const { plotData, plotConfig } = plotConfigSelector(plotKey);
  const tableData = plotData?.data || [];
  const tableHeader = tableData[0] || [];
  const tableBody = tableData.slice(1);
  const [maxRowLength, setMaxRowLength] = React.useState(0);

  React.useEffect(() => {
    let max = maxRowLength;
    tableBody.forEach((row) => {
      if (row.length > max) {
        max = row.length;
      }
    });

    setMaxRowLength(max);
  }, [plotData]);

  const isObject = (value) => value !== null && typeof value === 'object' && !Array.isArray(value);

  const renderTableCell = React.useCallback((row, index) => {
    const cell = row[index];
    let colSpan = 1;

    if (row.length < maxRowLength && index === row.length - 1) {
      colSpan = maxRowLength - row.length + 1;
    }

    if (isObject(cell)) {
      if (cell.type === 'html') {
        return (
          <td
            colSpan={cell.colSpan ?? colSpan}
            key={randomHex()}
            className={cell.class}
            style={cell.style}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: cell.content }}
          />
        );
      }

      return (
        <td key={randomHex()} className="px-3">
          <Col key={cell.id} sm={cell.columnSize}>
            <CustomField fieldDefinition={cell} />
          </Col>
        </td>
      );
    }

    // eslint-disable-next-line react/no-danger
    return <td colSpan={colSpan} key={randomHex()} className="px-3" dangerouslySetInnerHTML={{ __html: cell }} />;
  });

  return (
    <DashboxBody
      ref={boxContentRef}
      className="p-0 report-plot print-full-content-height"
      style={{ maxHeight: plotConfig.height, overflow: 'auto' }}
    >
      <Table size="sm" className="m-0" hover bordered>
        { tableHeader.length > 0 && (
          <thead>
            <tr>
              {tableHeader.map((header) => (
                <th key={randomHex()} className="px-3">{header}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          { tableBody.map((row) => (
            <tr key={randomHex()}>
              {row.map((_, index) => renderTableCell(row, index))}
            </tr>
          ))}
        </tbody>
      </Table>
    </DashboxBody>
  );
}

export function ReportTableCustomPlot({ plotKey }) {
  const plot = reportPlotSelector(plotKey, ['key']);
  const boxContentRef = React.useRef();

  return plot && (
    <>
      <ReportPlotDataLoader
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
      <ReportTableCustomPlotBox
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
    </>
  );
}
