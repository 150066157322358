import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import {
  createMap, createMarker, createMarkerWithWindow, setProjectMaps,
  createHeatMap,
  loadGeoJson,
} from '../../lib/map';

export default function LocationsMap({
  className = 'h-100',
  locations = [],
  maps = [],
  heatMap = [],
  locationWindow = null,
}) {
  return (
    <div className={className}>
      <Wrapper
        apiKey="AIzaSyBLgz7nax4w_u-He--taiLugOX6C8mgxGQ"
        libraries={['marker', 'visualization']}
        className="w-100 h-100"
      >
        <LocationsMapView
          locations={locations}
          maps={maps}
          heatMap={heatMap}
          locationWindow={locationWindow}
        />
      </Wrapper>
    </div>
  );
}

function LocationsMapView({
  locations, maps, locationWindow, heatMap,
}) {
  const ref = React.useRef(null);
  const [mapState, setMapState] = React.useState(null);
  const [mapBound, setMapBound] = React.useState(null);
  const [heatMapState, setHeatMapState] = React.useState(null);
  const [markersHash, setMarkersHash] = React.useState({});
  const [centered, setCentered] = React.useState(false);

  const getGeoJson = React.useCallback(async () => {
    if (!maps || maps.length === 0) return null;

    const hash = {};

    await Promise.all(maps.map(async (map) => {
      hash[map.id] = await loadGeoJson(map);
    }));

    return hash;
  });

  const setMaps = React.useCallback(async () => {
    const hash = await getGeoJson();
    setProjectMaps(mapState, maps, hash);
  });

  React.useEffect(() => {
    if (!ref.current) return;
    if (!locations) return;

    const map = mapState || createMap(ref);
    const bounds = mapBound || new global.window.google.maps.LatLngBounds();
    const heatmapdata = [];

    locations.forEach((location) => {
      const previousMarker = markersHash[location.code];

      if (previousMarker && previousMarker.location === location) {
        return;
      }

      markersHash[location.code]?.marker.setMap(null);

      const marker = locationWindow
        ? createMarkerWithWindow(map, location, locationWindow)
        : createMarker(map, location);

      bounds.extend(marker.position);

      setMarkersHash((prev) => ({
        ...prev,
        [location.code]: { marker, location },
      }));
    });

    const consolidatedHeatMap = [];

    heatMap.forEach((h) => {
      const index = consolidatedHeatMap.findIndex(
        (point) => point.lat === h.lat && point.lng === h.lng,
      );

      if (index === -1) {
        consolidatedHeatMap.push({
          lat: h.lat,
          lng: h.lng,
          hasOpenObservation: h.status === 'open',
        });
      } else {
        // eslint-disable-next-line no-lonely-if
        if (h.status === 'open') {
          consolidatedHeatMap[index].hasOpenObservation = true;
        }
      }
    });

    consolidatedHeatMap.forEach((point) => {
      const latLngPoint = {
        location: new global.window.google.maps.LatLng(point.lat, point.lng),
        weight: point.hasOpenObservation ? 3 : 0.5,
      };
      bounds.extend(latLngPoint.location);
      heatmapdata.push(latLngPoint);
    });

    const gradient = [
      'rgba(0, 255, 0, 0)',
      'rgba(0, 128, 0, 1)',
      'rgba(255, 0, 0, 1)',
    ];
    if (!heatMapState && heatmapdata.length > 0) {
      setHeatMapState(createHeatMap(map, heatmapdata, gradient));
    }

    if (!centered && locations.length > 0) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);

      if (map.getZoom() > 15) {
        map.setZoom(15);
      }

      setCentered(true);
    }

    setMapState(map);
    setMapBound(bounds);
  }, [ref, mapState, locations, heatMap]);

  React.useEffect(() => {
    if (!mapState) return;
    if (!maps) return;

    setMaps();
  }, [maps]);

  return <div ref={ref} id="map" className="w-100 h-100" />;
}
