import React from 'react';
import { Dashbox } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { LocationAlertIcon, LocationAlertText } from '../../location/LocationAlert.jsx';
import { Translator } from '../../../global/translator';

export const ReportScadaMetricLegend = React.memo(() => {
  const translator = new Translator();
  const metricMessage = {
    success: 'messages.alert-success-message',
    warning: 'messages.alert-warning-message',
    danger: 'messages.alert-danger-message',
    unknown: 'messages.alert-unknown-message',
  };

  return (
    <Dashbox className="p-2">
      {Object.keys(metricMessage).map((key) => (
        <div key={key} className="d-flex align-items-center">
          <LocationAlertIcon state={key} className="mr-2" />
          <LocationAlertText>{ translator.get(metricMessage[key])}</LocationAlertText>
        </div>
      ))}
    </Dashbox>
  );
});
