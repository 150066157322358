import * as React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Translator } from '../../global/translator';
import { Input, Select } from '../../duxfront/duxstrap/components/Form.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { mapWeightOptions } from '../../lib/map';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';

export function LocationsMapSelection({
  className,
  maps,
  selectedMaps,
  onSelectionChange,
}) {
  const translator = new Translator();
  const [map, setMap] = React.useState({});
  const [addMap, setAddMap] = React.useState(false);
  const defaultColor = '#000000';

  React.useEffect(() => {
    if (!maps || maps.length === 0) {
      return;
    }

    setMap({
      ...maps[0],
      ...map,
      weight: mapWeightOptions[0].value,
      color: defaultColor,
    });
  }, [maps]);

  const onSelectedMapChange = React.useCallback((e) => {
    // eslint-disable-next-line radix
    const selectedMap = maps.find((k) => k.id === parseInt(e.value));
    setMap({ ...selectedMap, weight: mapWeightOptions[0].value, color: defaultColor });
  });

  const addMapToList = React.useCallback(() => {
    if (!map.id) return;
    setAddMap(true);
  });

  const removeMapFromList = React.useCallback((mapToRemove) => {
    onSelectionChange(selectedMaps.filter((item) => item.id !== mapToRemove.id));
  });

  React.useEffect(() => {
    if (!addMap) return;

    const index = selectedMaps.findIndex((item) => item.id === map.id);

    if (index !== -1) {
      const newList = [...selectedMaps];
      newList[index] = map;
      onSelectionChange(newList);
      setAddMap(false);
      return;
    }

    onSelectionChange([...selectedMaps, map]);
    setAddMap(false);
  }, [addMap]);

  return (
    <div className={className}>
      <Row>
        <Col sm={12} md={4}>
          <Select
            id="title"
            label={translator.get('general.title')}
            options={maps.map((k) => ({ value: k.id, name: k.title }))}
            onChange={onSelectedMapChange}
          />
        </Col>

        <Col sm={12} md>
          <Input
            id="color"
            type="color"
            defaultValue={defaultColor}
            onChange={(e) => setMap({ ...map, color: e.target.value })}
            label={translator.get('general.color')}
          />
        </Col>

        <Col sm={12} md>
          <Select
            id="weight"
            onChange={(e) => setMap({ ...map, weight: e.value })}
            options={mapWeightOptions}
            label={translator.get('general.width')}
          />
        </Col>

        <Col sm={12} md={1} className="d-flex align-items-end justify-content-end mt-2 mt-md-0">
          <Button rounded icon size="sm" variant="outline-primary" disabled={addMap} onClick={addMapToList}>
            <Icon name="plus" offset={1} />
          </Button>
        </Col>
      </Row>
      {selectedMaps.length > 0 && (
      <Table size="sm" className="m-0 mt-3">
        <tbody>
          {selectedMaps.map((k) => (
            <tr key={k.id}>
              <td className="pl-2">{k.title}</td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td>
                <div style={{ backgroundColor: k.color, width: '20px', height: '20px' }} />
              </td>
              <td>{k.weight}</td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td className="p-0">
                <div className="d-flex align-items-center justify-content-end w-100">
                  <Button
                    rounded
                    icon
                    size="sm"
                    variant="outline-danger"
                    disabled={addMap}
                    onClick={() => removeMapFromList(k)}
                  >
                    <Icon name="minus" offset={1} />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      )}
    </div>
  );
}
