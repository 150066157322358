export function savePdf(data, filename) {
  const blob = new Blob([data], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function saveFile(data, filename) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function csvFromTable(table, filename) {
  if (!table) {
    throw new Error('table is required');
  }

  if (!filename) {
    throw new Error('filename is required');
  }

  const csv = [];
  const rows = table.querySelectorAll('tr');

  for (const row of rows) {
    const cols = row.querySelectorAll('td, th');
    const rowData = [];
    for (const col of cols) {
      rowData.push(col.innerText);
    }
    csv.push(rowData.join(','));
  }

  saveFile(csv.join('\n'), filename);
}
