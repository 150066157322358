import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';

export function DataSetColumns({ dataSet }) {
  const [show, setShow] = React.useState(false);
  const translator = new Translator();
  const self = React.createRef();
  const closeModal = React.useCallback(() => setShow(null));

  return (
    <>
      <Button
        type="button"
        size="xs"
        variant="outline-secondary"
        className="ml-2"
        withIcon
        onClick={() => setShow(true)}
      >
        <Icon name="eye" />
        { translator.get('general.columns').toUpperCase() }
      </Button>

      <Modal centered show={show} onHide={closeModal}>
        <Modal.Header closeButton onHide={closeModal}>
          <Modal.Title>
            {translator.get('general.columns')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={self} className="px-1 py-2">
          <Table size="sm" className="m-0">
            <tbody>
              {!dataSet.columns || dataSet.columns.length === 0 ? (
                <tr>
                  <td className="px-3">{translator.get('messages.no-results-found')}</td>
                </tr>
              ) : (
                dataSet.columns.map((column) => (
                  <tr key={column.id}>
                    <td className="pl-3 link-wrapper cursor-pointer">
                      <div className="text-primary font-weight-accent">{column.name}</div>
                      <div className="fs-12 text-gray-500">
                        {column.type}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
