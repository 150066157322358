import { Dropdown } from 'react-bootstrap';
import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';
import { deleteJSON } from '../../../duxfront/plugins/dux-utils';
import {
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  reportPlotMemo,
  reportErrorDispatch,
} from '../report-utils';

export function removePlot(plot, plotKeys, updatePlotKeys, updateError, onSuccess = null) {
  const translator = new Translator();

  BootBox.confirm({
    size: 'small',
    message: translator.get('messages.are-you-sure'),
    callback: (success) => {
      if (!success) return;

      deleteJSON(
        plot.updatePath,
        {},
        () => {
          updatePlotKeys(plotKeys.filter((key) => key !== plot.key));
          if (onSuccess) onSuccess();
        },
        () => {
          updateError({
            plotKey: plot.key,
            message: 'Could not remove plot. Please try again later.',
          });
        },
      );
    },
  });
}

export function ReportPlotDelete({ plotKey }) {
  const translator = new Translator();
  const plot = reportPlotMemo(plotKey, ['key', 'updatePath']);
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateError = reportErrorDispatch();

  const onClick = React.useCallback(() => {
    removePlot(plot, plotKeys, updatePlotKeys, updateError);
  }, [plot, plotKeys, updatePlotKeys, updateError]);

  return (
    <Dropdown.Item onClick={onClick}>
      <Icon name="times" className="text-primary mr-2" />
      { translator.get('general.remove') }
    </Dropdown.Item>
  );
}
