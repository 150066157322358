import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReportModalWrapper } from './ReportModalWrapper.jsx';
import { MODAL_TYPES, reportModalTypeDispatch, reportSelector } from '../report-utils';
import { PdfProvider } from './ReportPdf.jsx';
import { createConfig, generatePlotImage } from '../../../lib/pdf';
import { plotListSelector } from '../report_interactive_plot/plot-utils';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Translator } from '../../../global/translator';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Project } from '../../../global/project';

export function ReportExportOptions() {
  const report = reportSelector(['config']);
  const updateSelectedModal = reportModalTypeDispatch();

  return report && (
    <ReportModalWrapper title="Export Options" modalType={MODAL_TYPES.REPORT_EXPORT_OPTIONS}>
      <div className="px-3 pb-2">
        <ReportExportOptionsForm onClose={() => updateSelectedModal(null)} />
      </div>
    </ReportModalWrapper>
  );
}

export function ReportExportOptionsForm({ onClose }) {
  const translator = new Translator();
  const project = new Project();
  const report = reportSelector(['title', 'config']) || {};
  const plots = plotListSelector() || [];
  const [form, setForm] = React.useState(null);
  const [config, setConfig] = React.useState(null);
  const [exporting, setExporting] = React.useState(false);

  React.useEffect(() => {
    setForm(report.config.pdf?.pages.length > 0
      ? JSON.parse(JSON.stringify(report.config.pdf))
      : createConfig(plots, 'landscape', project.config.reports.backgroundImage));
  }, [report]);

  const generatePlot = React.useCallback(async (content) => {
    const plot = plots.find((p) => p.plot.key === content.key);

    return {
      ...content,
      data: await generatePlotImage(content.key),
      hidden: plot.plotConfig.hidden === true,
    };
  });

  const loadPlots = React.useCallback(async () => {
    setExporting(true);

    await Promise.all(form.pages.map(async (page) => {
      await Promise.all(page.content.map(async (pageContent, i) => {
        if (pageContent.type === 'plot') {
          // eslint-disable-next-line no-param-reassign
          page.content[i] = await generatePlot(pageContent);
        }

        if (pageContent.type === 'row') {
          await Promise.all(pageContent.columns.map(async (column, y) => {
            if (column.type === 'plot') {
              // eslint-disable-next-line no-param-reassign
              pageContent.columns[y] = await generatePlot(column);
            }
          }));
        }
      }));
    }));

    form.pages = form.pages.filter(
      (page) => page.content.length === 0 || page.content.some((content) => !content.hidden),
    );

    setConfig(form);
  });

  const changeReportOrientation = React.useCallback((orientation) => {
    if (!report.config.pdf) {
      setForm(createConfig(plots, orientation, project.config.reports.backgroundImage));
      return;
    }

    form.pages.forEach((page) => {
      // eslint-disable-next-line no-param-reassign
      page.orientation = orientation;
    });

    setForm({ ...form });
  });

  const onExport = React.useCallback(() => {
    setConfig(null);
    setForm(null);
    setExporting(false);
    onClose();
  });

  return (
    <>
      {!report.config.pdf && (
        <Row className="gutter-2">
          <Col sm={12}>
            <Select
              label={translator.get('general.orientation')}
              options={[{ name: 'Landscape', value: 'landscape' }, { name: 'Portrait', value: 'portrait' }]}
              onChange={(e) => changeReportOrientation(e.value)}
            />
          </Col>
        </Row>
      )}

      {report.config.pdf && (
        <Row className="gutter-2 my-3">
          <Col sm={12}>
            <p className="text-muted text-center">
              { translator.get('messages.empty-export-options') }
            </p>
          </Col>
        </Row>
      )}

      <Row className="mt-3">
        <Col sm={6}>
          <Button size="sm" variant="gray-200" onClick={onClose} withIcon block>
            <Icon name="times" />
            { translator.get('general.cancel').toUpperCase() }
          </Button>
        </Col>
        <Col sm={6}>
          <Button size="sm" variant="primary" disabled={exporting} onClick={loadPlots} withIcon block>
            <Icon name="file-pdf-o" />
            { translator.get('general.export_report').toUpperCase() }
          </Button>
        </Col>
      </Row>

      <PdfProvider config={config} onExport={onExport} />
    </>
  );
}
