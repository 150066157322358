import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Spacer } from '../../../duxfront/duxstrap/components/Spacer.jsx';
import { Dashbox, DashboxBody } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { Project } from '../../../global/project';
import { Translator } from '../../../global/translator';
import { CustomFields } from '../../custom_fields/CustomFields.jsx';
import { ReportModalWrapper } from './ReportModalWrapper.jsx';
import { ReportFormWrapper } from './ReportFormWrapper.jsx';
import {
  MODAL_TYPES,
  reportDispatch,
  reportModalTypeDispatch,
  reportSelector,
} from '../report-utils';

function EditCustomFieldsForm({ modal = true, compact = false }) {
  const project = new Project();
  const report = reportSelector(['updatePath', 'config']);
  const updateReport = reportDispatch();
  const updateReportModalType = reportModalTypeDispatch();
  const formId = 'update_custom_fields';
  const readOnly = !project.userCan('edit_reports');

  const onResponse = React.useCallback((data) => {
    updateReport(data.object);
    updateReportModalType(null);
    global.window.location.reload();
  });

  return (
    <ReportFormWrapper
      formId={formId}
      afterSave={onResponse}
      updatePath={report.updatePath}
      modal={modal}
      compact={compact}
      readOnly={readOnly}
    >
      <CustomFields formId={formId} fields={report.config.customFields} readOnly={readOnly} />
    </ReportFormWrapper>
  );
}

function EditCustomFieldsModal() {
  const report = reportSelector(['config']);
  const translator = new Translator();
  const title = translator.get('general.properties');
  const hasTable = report?.config?.customFields?.some((field) => field.type === 'table');

  return (
    <ReportModalWrapper
      size={hasTable ? 'lg' : 'md'}
      title={title}
      modalType={MODAL_TYPES.EDIT_CUSTOM_FIELDS}
    >
      <EditCustomFieldsForm />
    </ReportModalWrapper>
  );
}

function EditCustomFieldsBox() {
  const report = reportSelector(['config']);
  const compact = report?.config?.customFieldsFormType === 'inline_compact';

  return (
    <>
      <Col sm={compact ? 6 : 12}>
        <Dashbox>
          <DashboxBody className="p-0 pt-3" style={{ overflowY: 'unset' }}>
            <EditCustomFieldsForm modal={false} compact={compact} />
          </DashboxBody>
        </Dashbox>
      </Col>
      <Spacer size={0} block />
    </>
  );
}

export function ReportEditCustomFieldsForm() {
  const report = reportSelector(['config']);
  const customFields = report?.config?.customFields || [];
  const formType = report?.config?.customFieldsFormType || 'modal';

  return customFields.length > 0 && (
    formType !== 'modal'
      ? <EditCustomFieldsBox />
      : <EditCustomFieldsModal />
  );
}
