/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Dropdown, Table } from 'react-bootstrap';
import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';
import {
  reportPlotSelectedKeyDispatch,
  reportModalTypeDispatch,
  MODAL_TYPES,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
  reportModalTypeSelector,
  reportPlotDispatch,
} from '../report-utils';
import { ReportPlotModalWrapper } from './ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from './ReportPlotFormWrapper.jsx';
import { DateRangePicker, Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { parseForm, patchJSON, randomHex } from '../../../duxfront/plugins/dux-utils';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { convertToIsoString, convertToUserLocale, dateFromIsoString } from '../../../duxfront/duxstrap/lib/date';
import { plotConfigSelector } from '../report_interactive_plot/plot-utils';
import { globalObject } from '../../../global/global_object';

export function ReportPlotAnnotations({ plotKey }) {
  const translator = new Translator();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();

  const launchModal = React.useCallback(() => {
    updateSelectedPlotKey(plotKey);
    updateSelectedModalType(MODAL_TYPES.PLOT_ANNOTATIONS);
  });

  return (
    <Dropdown.Item onClick={launchModal}>
      <Icon name="pencil" className="text-primary mr-2" />
      { translator.get('general.annotations') }
    </Dropdown.Item>
  );
}

function AnnotationForm({
  formId,
  plotKey,
  onSave = null,
  onRemove = null,
  onClose = null,
  annotation = null,
}) {
  const translator = new Translator();
  const { plotConfig } = plotConfigSelector(plotKey);
  const [orientation, setOrientation] = React.useState(annotation?.orientation || 'horizontal');
  const positions = [
    { value: 'center', name: translator.get('general.center') },
    { value: 'top', name: translator.get('general.top') },
    { value: 'right', name: translator.get('general.right') },
    { value: 'bottom', name: translator.get('general.bottom') },
    { value: 'left', name: translator.get('general.left') },
  ];

  const orientations = [
    { value: 'horizontal', name: translator.get('general.horizontal') },
    { value: 'vertical', name: translator.get('general.vertical') },
  ];

  const axisOptions = plotConfig.yAxis.map((a) => ({ value: a.label, name: a.label }));

  const parseAnnotation = React.useCallback((e) => {
    const form = parseForm(e.target)[formId];

    return {
      id: annotation?.id ?? randomHex(),
      label: form.label,
      position: form.position,
      color: form.color,
      axis: form.axis,
      orientation: form.orientation,
      value: orientation === 'vertical'
        ? convertToIsoString(form.value, globalObject.duxfront?.layout?.language)
        : form.value,
    };
  });

  const createAnnotation = !onSave ? null : React.useCallback((e) => {
    e.preventDefault();
    onSave(parseAnnotation(e));
    onClose();
  });

  const removeAnnotation = !onRemove ? null : React.useCallback(() => {
    onRemove(annotation);
    onClose();
  });

  return (
    <ReportPlotFormWrapper formId={formId} onSave={createAnnotation} onRemove={removeAnnotation} onClose={onClose}>
      <Col sm={6}>
        <Input
          id="label"
          defaultValue={annotation?.label}
          formId={formId}
          label={translator.get('general.label')}
          validation="required"
        />
      </Col>

      <Col sm={6}>
        <Select
          id="position"
          defaultValue={annotation?.position}
          formId={formId}
          label={translator.get('general.position')}
          options={positions}
          validation="required"
        />
      </Col>

      <Col sm={6}>
        <Input
          type="color"
          defaultValue={annotation?.color}
          id="color"
          formId={formId}
          label={translator.get('general.color')}
          validation="required"
        />
      </Col>

      <Col sm={6}>
        <Select
          id="axis"
          defaultValue={annotation?.axis}
          formId={formId}
          options={axisOptions}
          label={translator.get('general.axis')}
          validation="required"
        />
      </Col>

      <Col sm={6}>
        <Select
          id="orientation"
          defaultValue={annotation?.orientation}
          formId={formId}
          options={orientations}
          label={translator.get('general.orientation')}
          validation="required"
          onChange={(e) => setOrientation(e.value)}
        />
      </Col>

      <Col sm={6}>
        {orientation === 'horizontal' && (
          <Input
            id="value"
            defaultValue={annotation?.value}
            formId={formId}
            label={translator.get('general.value')}
            validation="required"
          />
        )}

        {orientation === 'vertical' && (
          <DateRangePicker
            id="value"
            startDate={dateFromIsoString(annotation?.value)}
            endDate={dateFromIsoString(annotation?.value)}
            formId={formId}
            label={translator.get('general.value')}
            validation="required"
            language={globalObject.duxfront?.layout?.language}
            singleDatePicker
          />
        )}

      </Col>
    </ReportPlotFormWrapper>
  );
}

export function ReportPlotAnnotationsForm() {
  const translator = new Translator();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const selectedModalType = reportModalTypeSelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const updateReportPlot = reportPlotDispatch(reportPlotSelectedKey);
  const formId = 'plot_annotations';
  const title = translator.get('general.annotations');
  const [formKey, setFormKey] = React.useState('');
  const [annotations, setAnnotations] = React.useState([]);
  const [selectedAnnotation, setSelectedAnnotation] = React.useState(null);

  React.useEffect(() => {
    if (!selectedModalType) {
      setFormKey('');
    }
  }, [selectedModalType]);

  React.useEffect(() => {
    if (!plot?.config?.annotations) return;
    setAnnotations(plot.config.annotations);
  }, [plot]);

  const updatePlot = React.useCallback((updatedAnnotations) => {
    patchJSON(
      plot.updatePath,
      { [formId]: updatedAnnotations },
      (data) => {
        updateReportPlot(data.object);
        setAnnotations(data.object.config.annotations);
        setFormKey('');
      },
    );
  });

  const create = React.useCallback((annotation) => {
    updatePlot([...annotations, annotation]);
  });

  const update = React.useCallback((annotation) => {
    updatePlot(annotations.map((item) => (item.id === annotation.id ? annotation : item)));
  });

  const remove = React.useCallback((annotation) => {
    updatePlot(annotations.filter((item) => item.id !== annotation.id));
  });

  const setUpdateForm = React.useCallback((annotation) => {
    setFormKey('UPDATE');
    setSelectedAnnotation(annotation);
  });

  return plot && (
    <ReportPlotModalWrapper title={title} modalType={MODAL_TYPES.PLOT_ANNOTATIONS}>
      {formKey === '' && (
        <div className="px-3">
          <Table size="sm" className="m-0">
            <thead>
              <tr>
                <th>{translator.get('general.label')}</th>
                <th>{translator.get('general.color')}</th>
                <th>{translator.get('general.value')}</th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th />
              </tr>
            </thead>
            <tbody>
              {annotations.map((a) => (
                <tr key={randomHex()}>
                  <td>
                    <p
                      className="cursor-pointer"
                      style={{ textDecoration: 'underline' }}
                      onClick={() => setUpdateForm(a)}
                    >
                      {a.label}
                    </p>
                  </td>

                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td>
                    <div style={{ backgroundColor: a.color, width: '20px', height: '20px' }} />
                  </td>

                  <td>
                    {a.orientation === 'vertical'
                      ? convertToUserLocale(a.value, globalObject.duxfront?.layout?.language)
                      : a.value }
                  </td>

                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="p-0">
                    <div className="d-flex align-items-center justify-content-end w-100">
                      <Button rounded icon size="xs" variant="outline-danger" onClick={() => remove(a)}>
                        <Icon name="trash" offset={1} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}

              <tr key="location-module-add-button">
                <td className="px-3 text-center" colSpan={4}>
                  <Button size="xs" onClick={() => setFormKey('ADD')} withIcon>
                    <Icon name="plus" />
                    { translator.get('general.add').toUpperCase() }
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {formKey === 'ADD' && (
        <AnnotationForm
          formId={formId}
          plotKey={reportPlotSelectedKey}
          onClose={() => setFormKey('')}
          onSave={create}
        />
      )}

      {formKey === 'UPDATE' && (
        <AnnotationForm
          formId={formId}
          plotKey={reportPlotSelectedKey}
          onClose={() => setFormKey('')}
          onSave={update}
          annotation={selectedAnnotation}
        />
      )}
    </ReportPlotModalWrapper>
  );
}
