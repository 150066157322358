import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { generatePlotImage } from '../../../lib/pdf';
import { plotConfigSelector } from './plot-utils';

export function ReportInteractivePlotZoomOut({ plotApi }) {
  const zoomOut = React.useCallback(() => {
    plotApi.current.zoomOut();
  });

  return (
    <Icon name="search-minus" onClick={zoomOut} className="mr-2 cursor-pointer" />
  );
}

export function ReportInteractivePlotDownload({ plotKey }) {
  const { plotConfig } = plotConfigSelector(plotKey);

  const download = React.useCallback(async () => {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = await generatePlotImage(plotKey, '');
    hiddenElement.target = '_blank';
    hiddenElement.download = plotConfig.title;
    hiddenElement.click();
    hiddenElement.remove();
  });

  return (
    <Dropdown.Item onClick={download}>
      <Icon name="download" className="text-primary mr-2" />
      Download
    </Dropdown.Item>
  );
}

export function ReportInteractivePlotCopy({ plotApi }) {
  return (
    <Dropdown.Item onClick={() => plotApi.current.clipboard()}>
      <Icon name="clipboard" className="text-primary mr-2" />
      Copy
    </Dropdown.Item>
  );
}
