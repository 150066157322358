import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  usePDF,
} from '@react-pdf/renderer';
import moment from 'moment';
import { savePdf } from '../../../lib/file';
import {
  reportDateRangeSelector, reportSelector,
} from '../report-utils';
import { randomHex } from '../../../duxfront/plugins/dux-utils';
import { styles } from '../../../lib/pdf';
import { Translator } from '../../../global/translator';
import { Duxfront } from '../../../global/duxfront';

function ReportHeader({ title, dateRange }) {
  const translator = new Translator();
  const duxfront = new Duxfront();

  return (
    <View style={{
      flexDirection: 'row',
      marginBottom: '16px',
      paddingHorizontal: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      minHeight: '60px',
    }}
    >
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.headerTitle}>{title}</Text>
        <Text style={{ ...styles.headerSubtitle, marginTop: '4px', marginBottom: '2px' }}>
          {`${dateRange.startDate} - ${dateRange.endDate}`}
        </Text>
        <Text style={{ ...styles.headerSubtitle }}>
          {
          `${translator.get('general.generated_at')}: ${moment().format(translator.get('formats.basic-date-time-js'))}`
          }
        </Text>
      </View>

      {duxfront.institutional.logo && (
        <Image src={duxfront.institutional.logo} style={{ maxHeight: '60px', maxWidth: '100px' }} />
      )}
    </View>
  );
}

function ReportWidget({ content }) {
  if (!content) return null;

  const renderItem = (item) => {
    if (item.type === 'text') {
      return (
        <Text key={item.key} style={item.style}>
          {item.text}
        </Text>
      );
    }

    if (item.type === 'plot' && item.data) {
      return (
        <Image
          key={item.key}
          src={item.data}
          style={{ ...styles.plot, ...item.style }}
        />
      );
    }

    return null;
  };

  return content.map((item) => {
    if (item.type === 'row') {
      return (
        <View
          key={item.key}
          style={{ ...styles.row, ...item.style }}
        >
          {item.columns.map((column) => renderItem(column))}
        </View>
      );
    }

    return renderItem(item);
  });
}

function ReportPage({ page, children }) {
  return (
    <Page size="A4" orientation={page.orientation}>
      <View style={{ ...styles.view, ...page.style }}>
        {children}
      </View>

      {page.background && <Image src={page.background} style={styles.pageBackground} />}
    </Page>
  );
}

function ReportPdf({ config, report, dateRange }) {
  return config && (
    <Document>
      {config.pages && config.pages.map((page) => (
        <ReportPage key={randomHex()} page={page}>
          <ReportHeader title={report.title} dateRange={dateRange} />
          <ReportWidget content={page.content} />
        </ReportPage>
      ))}
    </Document>
  );
}

export function PdfProvider({ config, onExport }) {
  const translator = new Translator();
  const report = reportSelector(['title', 'config']) || {};
  const dateRange = reportDateRangeSelector();
  const [instance, update] = usePDF({ document: null });

  React.useEffect(() => {
    if (!config) return;

    update(
      <ReportPdf
        config={config}
        report={report}
        dateRange={{
          startDate: moment(dateRange.startDate).format(translator.get('formats.basic-date-time-js')),
          endDate: moment(dateRange.endDate).format(translator.get('formats.basic-date-time-js')),
        }}
      />,
    );
  }, [config]);

  React.useEffect(() => {
    if (!instance.blob || instance.loading) return;

    savePdf(instance.blob, report.title);
    onExport();
  }, [instance]);

  return null;
}
