import * as React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { DataSetForm } from './DataSetForm.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';

export function ManageDataSet({ items, dataset }) {
  const [show, setShow] = React.useState(false);
  const [itemUpdate, setItemUpdate] = React.useState(null);
  const translator = new Translator();

  const onModalClose = React.useCallback(() => {
    setShow(false);
    setItemUpdate(null);
  });

  const openModal = React.useCallback((item = null) => {
    setItemUpdate(item);
    setShow(true);
  });

  return (
    <div className="d-flex flex-column w-100">
      <Table size="sm" className="m-0 border-bottom" hover>
        <thead>
          <tr>
            {dataset.columns.length > 0 && dataset.columns.map((column) => (
              <th key={column.id}>{column.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length > 0 && items.map((item) => (
            <tr key={item.id} className="cursor-pointer" onClick={() => openModal(item)}>
              {dataset.columns.map((column) => (column.type === 'date'
                ? (<td key={column.id}>{moment(item.data[column.id]).format('L')}</td>)
                : (<td key={column.id}>{item.data[column.id]}</td>)
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center mt-2">
        <Button
          type="button"
          size="xs"
          withIcon
          onClick={openModal}
        >
          <Icon name="plus" />
          { translator.get('general.add').toUpperCase() }
        </Button>
      </div>

      <DataSetForm
        dataSet={dataset}
        dataSetItem={itemUpdate}
        show={show}
        onClose={onModalClose}
      />
    </div>
  );
}
