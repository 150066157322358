import {
  parseDate, reportPlotDataSelector, reportPlotKeysSelector, reportPlotSelector,
} from '../report-utils';

export function mergePlotDataSetup(plot, plotData) {
  // Merge initial plot config with custom setup retrieved when loading the data
  if (plotData) {
    // eslint-disable-next-line no-param-reassign
    plot.config = { ...plot.config, ...plotData.setup };
  }

  return plot;
}

export function buildPlotConfig(plot, plotData = {}) {
  mergePlotDataSetup(plot, plotData);

  let plotType = plot.groupIntervals ? 'line' : 'time';

  if (plot.config.category) {
    plotType = 'category';
  }

  return {
    title: plot.config.title ?? plot.title,
    height: plot.config.height ?? plot.height,
    hidden: plot.config.hidden,
    timeInterval: plot.timeInterval,
    yAxis: plot.config.axis,
    type: plotType,
    smoothness: plot.config.config.smoothness,
    dataLabel: plot.config.dataLabel,
    data: plot.config.plots.map((plotDetails) => ({
      label: plotDetails.title,
      type: plotDetails.type,
      color: plotDetails.color,
      yAxisId: plotDetails.axis,
      values: { x: [], y: [] },
    })),
    annotations: plot.config.annotations || [],
  };
}

export function buildPlotPieConfig(plot, plotData = {}) {
  mergePlotDataSetup(plot, plotData);

  return {
    type: 'pie',
    data: plot.config.plots.map((plotDetails) => ({
      label: plotDetails.title,
      values: { x: [], y: [] },
    })),
  };
}

export function buildPlotPiperConfig(plot, plotData = {}) {
  mergePlotDataSetup(plot, plotData);

  return {
    type: 'piper',
    yAxis: [],
    data: plot.config.plots.map((plotDetails) => ({
      label: plotDetails.title,
      color: plotDetails.color,
      pointStyle: plotDetails.pointStyle,
      values: { x: [], y: [] },
    })),
    annotations: [],
  };
}

export function setPlotData(plot, plotApi, plotData) {
  if (!plotData) return;
  if (!plotApi) return;

  mergePlotDataSetup(plot, plotData);

  plot.config.plots.forEach((plotDetails, i) => {
    plotApi.setData(plotData.data[i], i);
  });

  plot.config.axis.forEach((axisDetails) => {
    plotApi.setYRange(
      undefined,
      undefined,
      undefined,
      axisDetails.id,
    );
  });

  if (plot.groupIntervals || plot.config.category) {
    plotApi.setXRange(undefined, undefined);
  } else {
    plotApi.setXRange(parseDate(plotData.startDate), parseDate(plotData.endDate));
  }

  plotApi.apply();
}

export function setPlotPieData(plot, plotApi, plotData) {
  if (!plotData) return;
  if (!plotApi) return;

  mergePlotDataSetup(plot, plotData);

  plot.config.plots.forEach((plotDetails, i) => {
    plotApi.setData(plotData.data[i], i);
  });

  plotApi.apply();
}

export function setPlotPiperData(plot, plotApi, plotData) {
  if (!plotData) return;
  if (!plotApi) return;

  mergePlotDataSetup(plot, plotData);

  plot.config.plots.forEach((_, i) => plotApi.setData(plotData.data[i], i));

  plotApi.apply();
}

export function plotConfigSelector(plotKey) {
  const plot = reportPlotSelector(plotKey);
  const plotData = reportPlotDataSelector(plotKey);
  const plotConfig = buildPlotConfig(plot, plotData);

  return { plot, plotData, plotConfig };
}

export function plotListSelector() {
  const plotKeys = reportPlotKeysSelector() || [];

  return plotKeys.map((plotKey) => {
    const plot = reportPlotSelector(plotKey);
    const plotData = reportPlotDataSelector(plotKey);
    const plotConfig = buildPlotConfig(plot, plotData);

    return { plot, plotData, plotConfig };
  });
}
