import * as React from 'react';
import { Table } from 'react-bootstrap';
import { snakeCase } from 'lodash';
import moment from 'moment';
import { Translator } from '../../../global/translator';
import { ReportModalWrapper } from './ReportModalWrapper.jsx';
import {
  MODAL_TYPES,
  reportDispatch,
  reportModalTypeDispatch,
  reportSelector,
} from '../report-utils';
import { Form, Input } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';
import { CoreFields } from '../../custom_fields/CoreFields.jsx';
import {
  deepTransformKeys,
  loadingOverlay,
  parseForm,
  patchJSON,
} from '../../../duxfront/plugins/dux-utils';

function ManageList({
  list, setList, setDataset, saveList,
}) {
  const translator = new Translator();
  const [editingId, setEditingId] = React.useState(0);

  const cloneDataset = React.useCallback((dataset) => {
    const id = list.length > 0 ? list[list.length - 1].id + 1 : 1;
    const newDataset = { ...dataset, name: '', id: list.length > 0 ? list[list.length - 1].id + 1 : 1 };
    setList([...list, newDataset]);
    setEditingId(id);
  });

  const removeDataset = React.useCallback((dataset) => {
    const newDatasets = list.filter((item) => item.id !== dataset.id);

    if (dataset.id === editingId) {
      setEditingId(0);
      setList(newDatasets);
      return;
    }

    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;
        setList(newDatasets);
        saveList(newDatasets);
      },
    });
  });

  const updateDatasetName = React.useCallback((e, dataset) => {
    const listClone = [...list];
    const newDataset = { ...dataset, name: e.target.value };
    const index = list.findIndex((item) => item.id === dataset.id);
    listClone[index] = newDataset;
    setList(listClone);
  });

  const onCloneSave = React.useCallback(() => {
    saveList(list);
    setEditingId(0);
  });

  return (
    <Table size="sm" className="m-0 mt-1" hover>
      <tbody>
        {list.length > 0 && list.map((item) => (
          <tr key={item.id}>
            <td>
              {item.id !== editingId && item.name}
              {item.id === editingId && (
                <Input defaultValue={item.name} onChange={(e) => updateDatasetName(e, item)} />
              )}
            </td>
            <td align="right">
              <Button
                type="button"
                size="xs"
                variant="outline-danger"
                icon
                onClick={() => removeDataset(item)}
              >
                <Icon name="times" offset={1} />
              </Button>

              {item.id !== editingId && (
              <>
                <Button
                  type="button"
                  size="xs"
                  variant="outline-primary"
                  icon
                  className="ml-1"
                  onClick={() => cloneDataset(item)}
                >
                  <Icon name="clone" offset={1} />
                </Button>

                <Button
                  type="button"
                  size="xs"
                  variant="primary"
                  icon
                  className="ml-1"
                  onClick={() => setDataset(item)}
                >
                  <Icon name="edit" offset={1} />
                </Button>
              </>
              )}
              {item.id === editingId && (
              <Button
                type="button"
                size="xs"
                variant="primary"
                icon
                className="ml-1"
                onClick={onCloneSave}
              >
                <Icon name="check" offset={1} />
              </Button>
              )}
            </td>
          </tr>
        ))}

        {list.length === 0 && (
          <tr>
            <td className="px-3">
              <p>{translator.get('messages.no-results-found')}</p>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

function ManageDataSet({ dataset, setDataset }) {
  const translator = new Translator();
  const [editing, setEditing] = React.useState(false);
  const formId = 'dataset';
  const self = React.createRef();

  const handleSave = React.useCallback((e) => {
    const isSaving = e.target !== undefined;
    if (isSaving) {
      e.preventDefault();
    }

    const form = parseForm(e.target || e.current).dataset;
    const newItems = dataset.items;

    Object.keys(form).forEach((key) => {
      const keySplitted = key.split('-');

      if (keySplitted.length !== 2) {
        return;
      }

      const [columnId, itemId] = keySplitted;
      // eslint-disable-next-line radix
      const item = newItems.find((i) => i.id === parseInt(itemId));
      const column = dataset.columns.find((c) => c.id === columnId);

      if (!item || !column) {
        return;
      }

      if (column.type === 'date') {
        item[columnId] = form[key] ? moment(form[key]).toISOString() : null;
      } else {
        item[columnId] = form[key];
      }
    });

    setDataset({ ...dataset, items: newItems }, isSaving);
    setEditing(false);
  });

  const fieldDefinitionByColumn = React.useCallback((column, item) => ({
    id: `${column.id}-${item.id}`,
    label: '',
    type: column.type,
    value: item[column.id],
  }));

  const appendItem = React.useCallback(() => {
    const newItems = dataset.items;
    const lastId = newItems.length > 0 ? newItems[newItems.length - 1].id : 0;

    newItems.push({ id: lastId + 1 });

    setDataset({ ...dataset, items: newItems }, false);
    setEditing(true);
  });

  return (
    <Form
      id={formId}
      onSubmit={handleSave}
      ref={self}
    >
      <div className="d-flex flex-column w-100">
        <Table size="sm" className="m-0" hover>
          <thead>
            <tr>
              {dataset.columns.length > 0 && dataset.columns.map((column) => (
                <th key={column.id}>{column.name}</th>
              ))}
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
            </tr>
          </thead>
          <tbody>
            {dataset.items.length > 0 && dataset.items.map((item) => (editing ? (
              <tr key={item.id}>
                {dataset.columns.map((column) => (
                /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
                  <td key={column.id}>
                    <CoreFields formId={formId} fieldDefinition={fieldDefinitionByColumn(column, item)} />
                  </td>
                ))}

                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td>
                  <Button
                    type="button"
                    size="xs"
                    variant="outline-danger"
                    icon
                    onClick={() => {
                      const newItems = dataset.items.filter((i) => i.id !== item.id);
                      setDataset({ ...dataset, items: newItems });
                    }}
                  >
                    <Icon name="trash" offset={1} />
                  </Button>
                </td>
              </tr>
            ) : (
              <tr key={item.id}>
                {dataset.columns.map((column) => (column.type === 'date'
                  ? (
                    <td key={column.id}>
                      {moment(item[column.id]).format('L')}
                    </td>
                  )
                  : (<td key={column.id}>{item[column.id]}</td>))) }

                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td />
              </tr>
            )))}

            <tr>
              <td colSpan={dataset.columns.length + 1} align="center">

                { editing ? (
                  <>
                    <Button
                      type="button"
                      size="xs"
                      variant="gray-200"
                      className="mr-3"
                      withIcon
                      onClick={() => { handleSave(self); setEditing(false); }}
                    >
                      <Icon name="eye" />
                      { translator.get('general.view').toUpperCase() }
                    </Button>

                    <Button size="xs" variant="primary" onClick={appendItem} withIcon>
                      <Icon name="plus" />
                      { translator.get('general.add').toUpperCase() }
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    size="xs"
                    variant="gray-200"
                    withIcon
                    onClick={() => setEditing(true)}
                  >
                    <Icon name="edit" />
                    { translator.get('general.edit').toUpperCase() }
                  </Button>
                ) }
              </td>
            </tr>
          </tbody>
        </Table>

        <div className="d-flex w-100 mt-3 justify-content-end">
          <Button
            type="button"
            size="sm"
            variant="gray-200"
            withIcon
            onClick={() => setDataset(null)}
            style={{ width: 150 }}
          >
            <Icon name="times" />
            { translator.get('general.back').toUpperCase() }
          </Button>

          <Button type="submit" size="sm" variant="primary" withIcon className="ml-2" style={{ width: 150 }}>
            <Icon name="check" />
            { translator.get('general.save').toUpperCase() }
          </Button>
        </div>
      </div>
    </Form>
  );
}

export function ReportDataSet() {
  const self = React.createRef();
  const translator = new Translator();
  const report = reportSelector(['updatePath', 'config']);
  const updateReport = reportDispatch();
  const updateReportModalType = reportModalTypeDispatch();
  const title = translator.get('general.datasets');
  const [dataset, setDataset] = React.useState(null);
  const [list, setList] = React.useState([]);

  const updateDataset = React.useCallback((payload) => {
    loadingOverlay(self.current, 'show');

    patchJSON(
      report.updatePath,
      payload,
      (data) => {
        loadingOverlay(self.current, 'hide');
        updateReport(data.object);
        updateReportModalType(null);
        global.window.location.reload();
      },
      () => {
        loadingOverlay(self.current, 'hide');
      },
    );
  });

  React.useEffect(() => {
    if (!report) return;
    if (!report.config.dataSets) return;

    setList(deepTransformKeys(report.config.dataSets, snakeCase));
  }, [report]);

  const handleDatasetChange = React.useCallback((newDataset, save) => {
    if (!newDataset) {
      setDataset(null);
      return;
    }
    const index = list.findIndex((item) => item.id === newDataset.id);
    list[index] = newDataset;

    setDataset(newDataset);
    setList([...list]);

    if (save) {
      updateDataset({ dataSets: list });
    }
  });

  return report && (
    <ReportModalWrapper
      title={dataset ? dataset.name : title}
      modalType={MODAL_TYPES.DATASET}
      fullscreen
    >
      <div className="px-3 pb-2">
        {dataset
          ? <ManageDataSet dataset={dataset} setDataset={handleDatasetChange} />
          : (
            <ManageList
              list={list}
              setList={setList}
              setDataset={setDataset}
              saveList={(newList) => updateDataset({ dataSets: newList })}
            />
          )}
      </div>
    </ReportModalWrapper>
  );
}
