import CryptoJS from 'crypto-js';
/* globals document window $ bootbox _ FileList */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

export const Translator = function (dictionary, lang) {
  const self = this;

  this.id = randomHex(16);
  this.loaded_dictionary = setDefault(dictionary, {});
  this.lang = lang;

  if (global.window) this.lang = setDefault(this.lang, $('html').attr('lang'));

  this.lang = setDefault(this.lang, 'en');

  this.dictionary = function () {
    if (self.loaded_dictionary instanceof Function) {
      self.loaded_dictionary = self.loaded_dictionary();
    }

    return self.loaded_dictionary || {};
  };

  this.get = function (key) {
    let dict = self.dictionary();
    const keys = key.split('.');

    for (let i = 0; i < keys.length; i += 1) {
      dict = dict[keys[i]];
      if (typeof dict === 'undefined') {
        return 'TRANSLATION ERROR';
      }
    }

    const response = dict[self.lang];

    if (typeof response === 'undefined') {
      return 'TRANSLATION ERROR';
    }
    return response;
  };

  this.add = function (dict) {
    self.loaded_dictionary = $.extend(true, self.dictionary(), dict);
  };
};

export const IframeLoader = function (url, options) {
  const self = this;

  options = $.extend(true, {
    type: 'modal', // modal or element
    method: 'get', // get, post, patch, put, delete or form
    classes: '',
    params: {},
    data: {},
    headers: {},
    form: null, // jQuery object when method is form
    onload() {},
    modal: {
      title: false,
      size: 'large',
      height: '75vh', // set to auto to size iframe to its full content
      classes: '',
      onclose() {},
      button: {
        label: '<i class="fa fa-check icon"></i> OK',
        className: 'btn btn-sm btn-outline-gray-700 btn-with-icon',
      },
      header_classes: 'm-0 py-2 pr-2 border-bottom',
      footer_classes: 'py-2 border-top',
    },
    overlay: {
      zIndex: 9999,
      background: 'rgba(255, 255, 255)',
    },
    element: {
      obj: null, // jQuery object
      height: '100%', // set to auto to size iframe to its full content
    },
  }, setDefault(options, {}));

  this.iframe = null;
  this.wrapper = null;

  this.urlWithParams = function () {
    let newUrl = url;
    if (!$.isEmptyObject(options.params)) {
      if (newUrl.includes('?')) {
        newUrl = `${newUrl}&${$.param(options.params)}`;
      } else {
        newUrl = `${newUrl}?${$.param(options.params)}`;
      }
    }
    return newUrl;
  };

  options.type = options.type.toLowerCase();
  options.method = options.method.toLowerCase();

  const frameName = `iframe_form_${Math.random().toString(36).substring(7)}${Math.random().toString(36).substring(7)}`;
  const frameWrapper = $(
    `<div><iframe frameborder="0" class="d-block w-100" name="${frameName} + id=${frameName}"></iframe></div>`,
  );
  const frameElement = frameWrapper.find('iframe');

  if (options.method === 'get') {
    frameElement.attr('src', this.urlWithParams());
  } else {
    frameElement.attr('src', '');
  }

  if (options.type === 'modal') {
    const modalOptions = {
      title: options.modal.title,
      message: frameWrapper.html(),
      size: options.modal.size,
      backdrop: true,
      closeButton: options.modal.title,
    };
    if (!options.modal.title) {
      modalOptions.buttons = {
        alert: options.modal.button,
      };
    }
    this.wrapper = bootbox.dialog(modalOptions);
    this.wrapper.on('hidden.bs.modal', options.modal.onclose);
    this.iframe = this.wrapper.find('iframe');
    this.iframe.css('height', options.modal.height);
    this.wrapper.addClass(options.modal.classes);
    this.wrapper.find('.modal-content').css('overflow', 'hidden');
    this.wrapper.find('.modal-body').addClass('p-0');
    this.wrapper.find('.bootbox-body').css('opacity', 0);
    if (options.modal.title) {
      this.wrapper.find('.modal-header').addClass(options.modal.header_classes);
    } else {
      this.wrapper.find('.modal-footer').addClass(options.modal.footer_classes);
    }
    setTimeout(function () {
      this.wrapper.find('.modal-body').loading('show', options.overlay);
    }, 500);
    this.iframe.on('load', function () {
      setTimeout(() => {
        self.wrapper.find('.bootbox-body').css('opacity', 1);
        self.wrapper.find('.modal-body').loading('hide');
      }, 500);
      if (options.modal.height === 'auto') {
        $(this).height($(this).contents().find('body').height());
      }
      options.onload();
    });
  } else if (options.type === 'element') {
    this.wrapper = options.element.obj;
    this.wrapper.html(frameWrapper.html());
    this.iframe = this.wrapper.find('iframe');
    this.iframe.css('height', options.element.height);
    this.wrapper.css('overflow', 'hidden');
    this.wrapper.loading('show', options.overlay);
    this.iframe.on('load', function () {
      const $this = $(this);
      self.wrapper.loading('hide');
      if (options.element.height === 'auto') {
        $this.css('min-height', '100%');
        $this.height($this.contents().find('body').height());
      }
      options.onload();
    });
  }

  this.iframe.addClass(options.classes);

  if (options.method === 'form') {
    url = options.form.attr('action');
    options.form.attr('target', this.iframe.attr('name'));
    options.form.attr('action', this.urlWithParams());
    options.form.submit();
  } else if (options.method !== 'get') {
    const form = $('<form class="d-none"><input name="data"></input></form>');
    form.attr('target', this.iframe.attr('name'));
    form.attr('action', this.urlWithParams());
    form.attr('method', options.method);
    if (typeof options.data === 'string') {
      form.find('input').val(options.data);
    } else {
      form.find('input').val(JSON.stringify(options.data));
    }
    this.wrapper.append(form);
    this.wrapper.find('form').submit();
  }
};

export const clipboardCopy = function (text) {
  const $tempInput = $('<textarea>');
  $('body').append($tempInput);
  $tempInput.val(text).select();
  document.execCommand('copy');
  $tempInput.remove();
};

export const clipboardCopyElement = function (element) {
  const $target = $(element);
  const $temp = $('<div>');

  $target.parent().append($temp);
  $temp.attr('contenteditable', true).html($target.get(0).outerHTML).select().on('focus', () => {
    document.execCommand('selectAll', false, null);
  })
    .focus();
  document.execCommand('copy');
  $temp.remove();
};

export const setDefault = function (arg, def) {
  return (typeof arg === 'undefined' ? def : arg);
};

// eslint-disable-next-line import/no-mutable-exports,no-var
export var getUrlParameter = function (param) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  let parameterName;
  let i;
  for (i = 0; i < sURLVariables.length; i += 1) {
    parameterName = sURLVariables[i].split('=');
    if (parameterName[0] === param) {
      return parameterName[1] === undefined ? true : decodeURIComponent(parameterName[1]);
    }
  }
};

// eslint-disable-next-line import/no-mutable-exports,no-var
export var getUrlParameters = function () {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  const parameters = {};

  for (let i = 0; i < sURLVariables.length; i += 1) {
    const parameterName = sURLVariables[i].split('=');
    parameters[parameterName[0]] = parameterName[1] === undefined
      ? ''
      : decodeURIComponent(parameterName[1]);
  }

  return parameters;
};

export const postFormData = function (url, form, success, error, headers) {
  let formData = null;

  if (form instanceof FormData) {
    formData = form;
  } else {
    formData = new FormData(form[0]);
  }

  headers = setDefault(headers, {});
  $.ajax({
    type: 'post',
    url,
    data: formData,
    headers,
    cache: false,
    contentType: false,
    processData: false,
    success,
    error,
  });
};

export const patchFormData = function (url, form, success, error, headers) {
  let formData = null;

  if (form instanceof FormData) {
    formData = form;
  } else {
    formData = new FormData(form[0]);
  }

  headers = setDefault(headers, {});
  $.ajax({
    type: 'patch',
    url,
    data: formData,
    headers,
    cache: false,
    contentType: false,
    processData: false,
    success,
    error,
  });
};

export const postFiles = function (url, files, success, error, headers, action = 'post') {
  const formData = new FormData();

  if (files instanceof FileList) {
    for (let i = 0; i < files.length; i += 1) {
      formData.append('files[]', files[i]);
    }
  } else {
    formData.append('file', files);
  }

  headers = setDefault(headers, {});
  $.ajax({
    type: action,
    url,
    data: formData,
    headers,
    cache: false,
    contentType: false,
    processData: false,
    success,
    error,
  });
};

export const patchFiles = function (url, files, success, error, headers) {
  postFiles(url, files, success, error, headers, 'patch');
};

export const getJSON = function (url, params, success, error = null, headers = {}, cors = false) {
  headers = setDefault(headers, {});
  cors = setDefault(cors, false);
  const xhrFields = {};
  if (cors) xhrFields.withCredentials = true;
  $.ajax({
    url,
    type: 'get',
    dataType: 'json',
    contentType: 'application/json; charset=UTF-8',
    headers,
    data: params,
    success,
    error,
    xhrFields,
  });
};

export const postJSON = function (url, body, success, error = null, headers = {}, cors = false) {
  const xhrFields = {};
  if (cors) xhrFields.withCredentials = true;
  $.ajax({
    url,
    type: 'post',
    dataType: 'json',
    contentType: 'application/json; charset=UTF-8',
    headers,
    data: JSON.stringify(body),
    success,
    error,
    xhrFields,
  });
};

export const patchJSON = function (url, body, success, error = null, headers = {}, cors = false) {
  const xhrFields = {};
  if (cors) xhrFields.withCredentials = true;
  $.ajax({
    url,
    type: 'patch',
    dataType: 'json',
    contentType: 'application/json; charset=UTF-8',
    headers,
    data: JSON.stringify(body),
    success,
    error,
    xhrFields,
  });
};

export const delJSON = function (url, body, success, error = null, headers = {}, cors = false) {
  const xhrFields = {};
  if (cors) xhrFields.withCredentials = true;
  $.ajax({
    url,
    type: 'delete',
    dataType: 'json',
    contentType: 'application/json; charset=UTF-8',
    headers,
    data: JSON.stringify(body),
    success,
    error,
    xhrFields,
  });
};

export const deleteJSON = function (url, body, success, error = null, headers = {}, cors = false) {
  headers = setDefault(headers, {});
  cors = setDefault(cors, false);
  const xhrFields = {};
  if (cors) xhrFields.withCredentials = true;
  $.ajax({
    url,
    type: 'delete',
    dataType: 'json',
    contentType: 'application/json; charset=UTF-8',
    headers,
    data: JSON.stringify(body),
    success,
    error,
    xhrFields,
  });
};

export const parseForm = function (form) {
  const output = {};

  const stripFormId = (key) => {
    if (form.id) {
      key = key.replace(form.id, '');
      key = key.replace('[', '');
      key = key.replace(']', '');
    }

    key = key.replace('[]', '');

    return key;
  };

  new FormData(form).forEach(
    (value, key) => {
      key = stripFormId(key);

      if (Object.prototype.hasOwnProperty.call(output, key)) {
        let current = output[key];
        if (!Array.isArray(current)) {
          current = [current];
          output[key] = current;
        }
        current.push(value);
      } else {
        output[key] = value;
      }
    },
  );

  $(form).find("input[type='checkbox']").each((_, el) => {
    const key = stripFormId($(el).attr('name'));
    output[key] = !!output[key];
  });

  if (!form.id) return output;

  const outputWithId = {};
  outputWithId[form.id] = output;
  return outputWithId;
};

export const parseFormData = function (form) {
  const formObj = Object.values(parseForm(form))[0];
  const formData = new FormData();

  Object.keys(formObj).forEach((key) => formData.append(key, formObj[key]));

  return formData;
};

export const isObject = function (item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
};

export const mergeDeep = function (target, source) {
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) {
          const t = {};
          t[key] = {};
          Object.assign(target, t);
        }
        mergeDeep(target[key], source[key]);
      } else {
        const t = {};
        t[key] = source[key];
        Object.assign(target, t);
      }
    });
  }
  return target;
};

export const randomColor = function () {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const randomHex = function (length = 16) {
  return _.times(length, () => _.random(0, 15).toString(16)).join('');
};

export const loadingOverlay = function (selector, action, options = { zIndex: 9999 }) {
  $(selector).loading(action, options);
};

export const loadJqueryHelpers = function () {
  $.fn.getSelector = function () {
    let element = this[0];
    // Get the xpath for this element.
    // This was easier than calculating the DOM using jQuery, for now.
    let xpath = '';
    for (; element && element.nodeType === 1; element = element.parentNode) {
      let id = $(element.parentNode).children(element.tagName).index(element) + 1;
      id > 1 ? (id = '[' + id + ']') : (id = ''); // eslint-disable-line
      xpath = `/${element.tagName.toLowerCase()}${id}${xpath}`;
    }
    // Get CSS selector for the calculated xpath
    let selector = xpath.substr(1).replace(/\//g, ' > ').replace(/\[(\d+)\]/g, ($0, i) => `:nth-child(${i})`);
    // Add the id if it has one
    if (typeof ($(this).attr('id')) !== 'undefined' && $(this).attr('id') !== null) {
      selector += `#${$(this).attr('id')}`;
    }
    // Add all classes if it has any
    if (typeof ($(this).attr('class')) !== 'undefined' && $(this).attr('class') !== null) {
      selector += `.${$(this).attr('class').replace(' ', '.')}`;
    }
    return selector;
  };

  $.fn.fileInput = function (finputSelector, fnameSelector) {
    const $this = $(this);
    const finput = $(finputSelector);
    const flabel = $(fnameSelector);

    $this.click(() => {
      finput.click();
    });

    finput.change(() => {
      let fname = '';
      const { files } = finput.get(0);
      if (files.length > 1) {
        fname += `${files.length} files:  `;
      }
      for (let i = 0; i < files.length; i += 1) {
        fname += `${files[i].name}  `;
      }
      if (flabel.is('input')) {
        flabel.val(fname);
      } else {
        flabel.text(fname);
      }
    });
  };

  $.fn.clipboardCopy = function () {
    clipboardCopyElement(this);
  };

  // Auto Initializers
  $(() => {
    // File Input
    (function () {
      $('[data-file-input]').each(function () {
        const $this = $(this);
        let finput = false;
        let flabel = false;

        if (typeof $this.attr('data-file-input') !== 'undefined') {
          finput = $this.attr('data-file-input');
        }

        if (typeof $this.attr('data-file-name') !== 'undefined') {
          flabel = $this.attr('data-file-name');
        }

        if (finput && flabel) {
          $this.fileInput(finput, flabel);
        }
      });
    }());

    // Scroll To Element
    (function () {
      // Get jQuery object from element id in anchor href  or selector in data-target
      const getTarget = function (anchor) {
        let target = false;
        if (typeof anchor.attr('data-scroll') !== 'undefined') {
          target = anchor.attr('data-scroll');
        } else if (anchor.is('a')) {
          target = `#${anchor.attr('href').split('#').pop()}`;
        }
        if (target) {
          target = $(target);
        }
        return target;
      };
      // Scroll to element on the page on anchor click
      $('.scroll-to, .scroll-link, [data-scroll]').click(function (e) {
        e.preventDefault();
        const anchor = $(this);
        const target = getTarget(anchor);
        if (target) {
          $('html, body').animate({
            scrollTop: target.offset().top - 20,
          }, 500);
          if (anchor.hasClass('list-group-item')) {
            anchor.parents('.list-group').find('.list-group-item').removeClass('active');
            anchor.addClass('active');
          }
        }
      });
      // Set anchor as active when target is hovered
      $('.scroll-to, .scroll-link, [data-scroll]').each(function () {
        const anchor = $(this);
        const target = getTarget(anchor);
        if (target) {
          target.hover(() => {
            if (anchor.hasClass('list-group-item')) {
              anchor.parents('.list-group').find('.list-group-item').removeClass('active');
              anchor.addClass('active');
            }
          });
        }
      });
    }());

    // Are you sure dialog
    (function () {
      $('.are-you-sure').click(function (e) {
        const self = $(this);
        bootbox.confirm(
          self.attr('data-confirm'),
          (result) => {
            if (result) {
              let anchor = self;
              if (!anchor.is('a')) {
                anchor = anchor.closest('a');
              }
              anchor = anchor.clone();
              anchor.removeAttr('data-confirm');
              anchor.removeClass('are-you-sure');
              anchor.addClass('d-none');
              anchor.attr('id', 'are-you-sure-temp-anchor');
              $('body').append(anchor);
              // eslint-disable-next-line prefer-destructuring
              anchor = $('#are-you-sure-temp-anchor')[0];
              anchor.click();
              anchor.remove();
            }
          },
        );
        e.preventDefault();
        return false;
      });
    }());

    // Link Wrapper
    (function () {
      $('.link-wrapper a').data('prevent_default', true);

      $('.link-wrapper').click(function () {
        const $this = $(this);
        $this.data('double_clicked', false);
        if (!$this.data('single_clicked')) {
          $this.data('single_clicked', true);
          setTimeout(() => {
            const anchor = $this.find('a');
            const target = anchor.attr('target') || '_self';
            if ($this.data('double_clicked')) {
              anchor.attr('target', '_blank');
            }
            anchor.data('prevent_default', false);
            anchor[0].click();
            anchor.attr('target', target);
            anchor.data('prevent_default', true);
            $this.data('single_clicked', false);
            $this.data('double_clicked', false);
          }, 250);
        } else {
          $this.data('double_clicked', true);
        }
      });

      $('.link-wrapper a').click(function (e) {
        if ($(this).data('prevent_default')) {
          e.preventDefault();
        }
      });
    }());
  });
};

export const appendArray = function (array, item) {
  if (Array.isArray(item)) {
    return array.concat(item);
  }

  return array.concat([item]);
};

export const updateByKey = function (array, newItem, keyAttr = 'key') {
  if (Array.isArray(newItem)) {
    return _.unionBy(newItem, array, keyAttr);
  }

  return _.unionBy([newItem], array, keyAttr);
};

export const updateByKeyValue = function (array, newItem, keyValue, keyAttr = 'key') {
  const newArray = array.map((item) => {
    if (item[keyAttr] === keyValue) return newItem;
    return item;
  });

  return updateByKey(newArray, newItem, keyAttr);
};

export const removeByKey = function (array, key, keyAttr = 'key') {
  return array.filter((item) => item[keyAttr] !== key);
};

export const deepTransformKeys = function (obj, transformFunc) {
  const isArray = Array.isArray(obj);
  const isObj = obj === Object(obj) && !isArray;

  if (!isArray && !isObj) return obj;

  return _.reduce(obj, (acc, value, key) => {
    const newKey = transformFunc(key);
    acc[newKey] = deepTransformKeys(value, transformFunc);
    return acc;
  }, isArray ? [] : {});
};

function sortObjectKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  } if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).sort().reduce((result, key) => {
      result[key] = sortObjectKeys(obj[key]);
      return result;
    }, {});
  }
  return obj;
}

export function objectHashedVersion(obj, prefix = 'v1') {
  const sortedObject = sortObjectKeys(obj);
  const serializedObject = JSON.stringify(sortedObject);
  const hash = CryptoJS.MD5(serializedObject).toString();

  return `${prefix}-${hash}`;
}
