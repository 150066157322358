import html2canvas from 'html2canvas';
import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  pageBackground: {
    zIndex: -1,
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  view: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingHorizontal: '10px',
    paddingTop: '42px',
    paddingBottom: '72px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    marginVertical: 'auto',
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: 'ultrabold',
    color: '#00344e',
  },
  headerSubtitle: {
    color: '#555',
    fontWeight: 'light',
    fontSize: 8,
  },
  plot: {
    flex: '0 0 100%',
    padding: '10px',
  },
});

export const calculateWidth = (width) => {
  const colWidth = 100 / 12;

  if (width.includes('12')) return colWidth * 12;
  if (width.includes('11')) return colWidth * 11;
  if (width.includes('10')) return colWidth * 10;
  if (width.includes('9')) return colWidth * 9;
  if (width.includes('8')) return colWidth * 8;
  if (width.includes('7')) return colWidth * 7;
  if (width.includes('6')) return colWidth * 6;
  if (width.includes('5')) return colWidth * 5;
  if (width.includes('4')) return colWidth * 4;
  if (width.includes('3')) return colWidth * 3;
  if (width.includes('2')) return colWidth * 2;
  if (width.includes('1')) return colWidth;
};

export const createConfig = (
  array,
  pageOrientation = 'landscape',
  background = '',
) => {
  const rows = [];
  let row = { type: 'row', columns: [] };
  let totalWidth = 0;

  array.forEach((item) => {
    if (item.plotConfig.hidden) return;

    const plotWidth = calculateWidth(item.plot.width);

    row.columns.push({
      type: 'plot',
      key: item.plot.key,
      data: null,
      plot: item.plot,
      plotConfig: item.plotConfig,
      style: {
        flex: `0 0 ${plotWidth}%`,
        maxWidth: `${plotWidth}%`,
        maxHeight: '100%',
      },
    });

    totalWidth += plotWidth;

    if (totalWidth >= 100) {
      rows.push(row);
      row = { type: 'row', columns: [] };
      totalWidth = 0;
    }
  });

  if (row.length > 0) {
    rows.push(row);
  }

  const perChunk = pageOrientation === 'landscape' ? 1 : 3;

  const rowsChunk = rows.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  const config = { pages: [] };

  rowsChunk.forEach((chunk) => {
    config.pages.push({
      background: background ?? '/report-frames/report-frame-7.png',
      orientation: pageOrientation,
      content: chunk,
    });
  });

  return config;
};

export const generatePlotImage = async (plotKey, style = 'box-shadow: none; border: 2px solid #e0e0e0;') => {
  const node = document.getElementById(plotKey);

  if (!node) return;

  node.style = style;

  const elementsToHide = node.querySelectorAll('.d-print-none');

  elementsToHide.forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element.style.display = 'none';
  });

  const canvas = await html2canvas(node, { allowTaint: true, useCORS: true, scale: 2 });

  node.style = '';
  elementsToHide.forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element.style.display = '';
  });

  return canvas.toDataURL('image/png');
};
