import * as React from 'react';
import { Col, Dropdown, Table } from 'react-bootstrap';
import { randomHex } from '../../../duxfront/plugins/dux-utils';
import { Dashbox, DashboxBody } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Project } from '../../../global/project';
import { Translator } from '../../../global/translator';
import {
  ReportPlotBoxFooter,
  ReportPlotBoxFullScreenIcon,
  ReportPlotBoxHeader,
} from '../report_plot_base/ReportPlotBox.jsx';
import { ReportPlotDataLoader } from '../report_plot_base/ReportPlotDataLoader.jsx';
import { ReportPlotRefreshData } from '../report_plot_base/ReportPlotRefreshData.jsx';
import { ReportPlotGeneral } from '../report_plot_base/ReportPlotGeneral.jsx';
import { ReportPlotClone } from '../report_plot_base/ReportPlotClone.jsx';
import { ReportPlotDelete } from '../report_plot_base/ReportPlotDelete.jsx';
import { ReportPlotAdvanced } from '../report_plot_base/ReportPlotAdvanced.jsx';
import { ReportTablePlotCopy, ReportTablePlotCsv } from './ReportTablePlotBase.jsx';
import {
  MODAL_TYPES,
  reportModalTypeDispatch,
  reportPlotDataSelector,
  reportPlotSelectedKeyDispatch,
  reportPlotSelector,
} from '../report-utils';

function ReportTablePlotBox({ plotKey, boxContentRef }) {
  const plot = reportPlotSelector(plotKey, ['key', 'order', 'columnSize']);
  const [fullScreen, setFullScreen] = React.useState(false);

  return (
    <Col sm={plot.columnSize} className="print-no-page-break" style={{ order: plot.order }}>
      <Dashbox id={plotKey} fullViewPort={fullScreen}>
        <ReportTablePlotHeader
          plotKey={plot.key}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          boxContentRef={boxContentRef}
        />
        <ReportTablePlotBody plotKey={plot.key} boxContentRef={boxContentRef} />
        <ReportPlotBoxFooter plotKey={plot.key} />
      </Dashbox>
    </Col>
  );
}

function ReportTablePlotHeader({
  plotKey, fullScreen, setFullScreen, boxContentRef,
}) {
  const plot = reportPlotSelector(plotKey, ['key', 'title']);
  const project = new Project();
  const translator = new Translator();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();

  const launchModal = React.useCallback((modalType) => {
    updateSelectedPlotKey(plot.key);
    updateSelectedModalType(modalType);
  });

  return (
    <ReportPlotBoxHeader plotKey={plot.key}>
      <ReportPlotBoxFullScreenIcon fullScreen={fullScreen} setFullScreen={setFullScreen} />
      { project.userCan('edit_reports') && (
        <Dropdown as="div" className="d-inline-block cursor-pointer ml-2">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Icon name="ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ReportPlotRefreshData plotKey={plot.key} />
            <ReportPlotGeneral plotKey={plot.key} />
            <Dropdown.Item onClick={() => launchModal(MODAL_TYPES.TABLE_PLOT_DATA)}>
              <Icon name="table" className="text-primary mr-2" />
              { translator.get('general.datas') }
            </Dropdown.Item>
            <ReportTablePlotCopy boxContentRef={boxContentRef} />
            <ReportTablePlotCsv plotKey={plot.key} boxContentRef={boxContentRef} />
            <ReportPlotClone plotKey={plot.key} />
            <ReportPlotDelete plotKey={plot.key} />
            <ReportPlotAdvanced plotKey={plot.key} />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ReportPlotBoxHeader>
  );
}

function ReportTablePlotBody({ plotKey, boxContentRef }) {
  const plot = reportPlotSelector(plotKey);
  const plotData = reportPlotDataSelector(plotKey);
  const tableData = plotData?.data || [];
  const tableHeader = tableData[0] || [];
  const tableBody = tableData.slice(1);

  return (
    <DashboxBody
      ref={boxContentRef}
      className="p-0 report-plot print-full-content-height"
      style={{ maxHeight: plot.height, overflow: 'auto' }}
    >
      <Table size="sm" className="m-0" hover bordered>
        { tableHeader.length > 0 && (
          <thead>
            <tr>
              {tableHeader.map((header) => (
                <th key={randomHex()} className="px-3">{header}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          { tableBody.map((row) => (
            <tr key={row[0]}>
              {row.map((cell) => (
                <td key={randomHex()} className="px-3">{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </DashboxBody>
  );
}

export function ReportTablePlot({ plotKey }) {
  const plot = reportPlotSelector(plotKey, ['key']);
  const boxContentRef = React.useRef();

  return plot && (
    <>
      <ReportPlotDataLoader
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
      <ReportTablePlotBox
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
    </>
  );
}
