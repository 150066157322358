import moment from 'moment';

const LOCALE_PT_BR = {
  format: 'DD/MM/YYYY',
  separator: ' - ',
  applyLabel: 'Aplicar',
  cancelLabel: 'Cancelar',
  fromLabel: 'De',
  toLabel: 'Até',
  customRangeLabel: 'Personalizado',
  daysOfWeek: [
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  firstDay: 0,
};

const LOCALE_ESP = {
  format: 'DD/MM/YYYY',
  separator: ' - ',
  applyLabel: 'Aplicar',
  cancelLabel: 'Cancelar',
  fromLabel: 'De',
  toLabel: 'Hasta',
  customRangeLabel: 'Personalizado',
  daysOfWeek: [
    'Dom',
    'Lun',
    'Mar',
    'Mié',
    'Jue',
    'Vie',
    'Sáb',
  ],
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  firstDay: 0,
};

export const getDateLocale = (lang, type, timePicker, format) => {
  const language = lang || 'en';
  let locale = { format: 'MM/DD/YYYY' };

  if (language === 'pt-br') {
    locale = LOCALE_PT_BR;
  }

  if (language === 'es') {
    locale = LOCALE_ESP;
  }

  if (format) {
    locale = { ...locale, format };
  }

  if (timePicker) {
    locale = { ...locale, format: `${locale.format} HH:mm` };
  }

  if (type === 'monthly') {
    locale = { ...locale, format: 'MMMM YYYY' };
  }

  return locale;
};

export const getDefaultRanges = (lang, parsedEndDate) => {
  const language = lang || 'en';
  let hours = 'hours';
  let days = 'days';
  let months = 'months';

  if (language === 'pt-br') {
    hours = 'horas';
    days = 'dias';
    months = 'meses';
  }

  if (language === 'es') {
    hours = 'horas';
    days = 'días';
    months = 'meses';
  }

  const ranges = {};
  ranges[`06 ${hours}`] = [parsedEndDate.clone().subtract(6, 'hours'), parsedEndDate];
  ranges[`12 ${hours}`] = [parsedEndDate.clone().subtract(12, 'hours'), parsedEndDate];
  ranges[`24 ${hours}`] = [parsedEndDate.clone().subtract(24, 'hours'), parsedEndDate];
  ranges[`07 ${days}`] = [parsedEndDate.clone().subtract(7, 'days'), parsedEndDate];
  ranges[`30 ${days}`] = [parsedEndDate.clone().subtract(30, 'days'), parsedEndDate];
  ranges[`03 ${months}`] = [parsedEndDate.clone().subtract(3, 'months'), parsedEndDate];
  ranges[`06 ${months}`] = [parsedEndDate.clone().subtract(6, 'months'), parsedEndDate];

  return ranges;
};

export const convertToIsoString = (date, lang = '') => {
  if (!date) {
    return null;
  }

  const format = getDateLocale(lang, '', true).format;

  return moment(date, format).format('YYYY-MM-DD HH:mm:ss');
};

export const dateFromIsoString = (date) => {
  if (!date) {
    return null;
  }

  return moment(date, 'YYYY-MM-DD HH:mm:ss');
};

export const convertToUserLocale = (date, lang = '') => {
  if (!date) {
    return null;
  }

  const format = getDateLocale(lang, '', true).format;

  return moment(date).format(format);
};
