import React from 'react';
import { Col, Modal } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Form, Input } from '../../../duxfront/duxstrap/components/Form.jsx';
import { loadingOverlay, parseForm, patchJSON } from '../../../duxfront/plugins/dux-utils';
import { Spacer } from '../../../duxfront/duxstrap/components/Spacer.jsx';

export function DataSetEdit({ dataSet }) {
  const [show, setShow] = React.useState(false);
  const translator = new Translator();
  const self = React.createRef();
  const formId = 'dataset';
  const closeModal = React.useCallback(() => setShow(false));

  const handleSave = React.useCallback((e) => {
    e.preventDefault();
    const form = parseForm(e.target || e.current).dataset;

    patchJSON(
      dataSet.updatePath,
      { id: dataSet.id, title: form.title },
      () => {
        loadingOverlay(self.current, 'hide');
        global.window.location.reload();
      },
      () => {
        loadingOverlay(self.current, 'hide');
      },
    );
  });

  return (
    <>
      <Button
        type="button"
        size="xs"
        variant="outline-dark"
        className="ml-2"
        withIcon
        onClick={() => setShow(true)}
      >
        <Icon name="eye" />
        { translator.get('general.edit').toUpperCase() }
      </Button>

      <Modal centered show={show} onHide={closeModal}>
        <Modal.Header closeButton onHide={closeModal}>
          <Modal.Title>
            {translator.get('general.edit')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={self}>
          <Form id={formId} onSubmit={handleSave} ref={self}>
            <Col sm={12}>
              <Input
                label={translator.get('general.title')}
                name="title"
                defaultValue={dataSet?.title}
              />
            </Col>

            <Spacer block />
            <Col sm={6}>
              <Button size="sm" variant="gray-200" onClick={closeModal} withIcon block>
                <Icon name="times" />
                { translator.get('general.cancel').toUpperCase() }
              </Button>
            </Col>

            <Col sm={6}>
              <Button size="sm" variant="primary" type="submit" withIcon block>
                <Icon name="check" />
                { translator.get('general.save').toUpperCase() }
              </Button>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
